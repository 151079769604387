import React from "react";
import { InfoWindow } from "@vis.gl/react-google-maps";
import { Typography } from "@mui/material";

const PlanningHoveredPolygonPopup = ({
  hoveredPolygonData,
  setHoveredPolygonData,
}) => {
  const data = hoveredPolygonData?.object?.properties;
  if(!data?.pathId) return;
  const [parent] = data?.pathId?.split("-");

  return (
    <>
      {hoveredPolygonData && (
        <InfoWindow
          position={{
            lat: hoveredPolygonData?.coordinate?.[1],
            lng: hoveredPolygonData?.coordinate?.[0],
          }}
          anchor="left"
          pixelOffset={[0, -20]}
          disableAutoPan={true}
          shouldFocus={false}
          onClose={() => setHoveredPolygonData(null)}
          closeButton={false}
        >
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Area/Polygon ID :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {parent}
            </Typography>
          </div>
          <div className="hoveredRow">
            <Typography component={"span"} variant="body2" fontWeight={600}>
              Service :{" "}
            </Typography>
            <Typography component={"span"} variant="body2">
              {data?.layerType}
            </Typography>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default PlanningHoveredPolygonPopup;
