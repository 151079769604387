import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIProvider } from "@vis.gl/react-google-maps";
import GISDashboardGoogleMaps from "./GISDashboardGoogleMaps";
import { TbTimeline } from "react-icons/tb";
import { Box, Typography } from "@mui/material";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import GISDashboardSidebar from "./GISDashboardSidebar";
import GISTimelineDrawer from "./Component/GISTimelineDrawer";
import { IoMdArrowDropright } from "react-icons/io";
import { GiMove } from "react-icons/gi";
import PathOfMotionControlDrawer from "./Component/PathOfMotionControlDrawer";
import { setBeaconsListData, setIsPathOfMotion } from "../../slices/gisDashboardSlice";
import { useLazyGetBeaconsListQuery } from "../../services/Properties";

const GISDashboardMapScreen = () => {
  const mapRef = useRef(null);
  const drawerRef = useRef();
  const dispatch = useDispatch();
  const isSummary = useSelector((state) => state.gisDashboard.isSummary);
  const detailedData = useSelector((state) => state.gisDashboard.detailedData);
  const isPathOfMotion = useSelector((state) => state.gisDashboard.isPathOfMotion);
  const isBeaconData = useSelector((state) => state.gisDashboard.beaconsListData);
  const [getBeaconsList, {isBeaconsLoading }] = useLazyGetBeaconsListQuery();
  const [drawerHeight, setDrawerHeight] = useState(0);
  const [isTimelineDrawerOpen, setIsTimelineDrawerOpen] = useState(false);
  const [isPlannerDrawerOpen, setIsPlannerDrawerOpen] = useState(true);
  const [isPathOfMotionDrawerOpen, setIsPathOfMotionDrawerOpen] =
    useState(false);
  const [tabValue, setTabValue] = useState(0);
  const mapData = useSelector((state) => state.global.mapData);
  const user = JSON.parse(localStorage.getItem("user"));

  const togglePlannerDrawer = () => {
    setIsPlannerDrawerOpen(!isPlannerDrawerOpen);
  };

  const getBeaconsListData = async () => {
    const response = await getBeaconsList({
      orgName: user?.organization
    });
    dispatch(setBeaconsListData(response?.data?.results))
  };

  useEffect(()=>{
    if(isBeaconData === null){
      getBeaconsListData();
    }
  },[isBeaconData])

  useEffect(() => {
    // Ensure the drawerRef is currently pointing to an element
    const drawerElement = drawerRef.current;
    if (!drawerElement) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDrawerHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(drawerElement);

    // Cleanup function to safely unobserve
    return () => {
      if (drawerElement) {
        resizeObserver.unobserve(drawerElement);
      }
    };
  }, [isTimelineDrawerOpen]);

  const handlePathOfMotionClick = () => {
    setIsPathOfMotionDrawerOpen(!isPathOfMotionDrawerOpen);
    dispatch(setIsPathOfMotion(!isPathOfMotion));
  };

  return (
    <div className="mapPanelContainer">
      <div className="mapboxMapContainer">
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
          <GISDashboardGoogleMaps mapData={mapData} mapRef={mapRef} />
        </APIProvider>
      </div>
      <Box
        className="sidebarButton"
        sx={{ top: "50%", left: isPlannerDrawerOpen ? "35vw" : "0vw" }}
        onClick={togglePlannerDrawer}
      >
        <IoMdArrowDropright fontSize={26} />
      </Box>

      {!isSummary && (
        <>
          <button className="viewMapControls" onClick={handlePathOfMotionClick}>
            <GiMove size={20} /> <Typography>Path of Motion</Typography>{" "}
            <TiArrowSortedDown />
          </button>
          <PathOfMotionControlDrawer
            detailsData={detailedData}
            isPathOfMotionDrawerOpen={isPathOfMotionDrawerOpen}
            handlePathOfMotionClick={handlePathOfMotionClick}
          />
        </>
      )}
      {isSummary && (
        <>
          <button
            className="viewTimelineButton"
            onClick={() => setIsTimelineDrawerOpen(!isTimelineDrawerOpen)}
          >
            <TbTimeline size={20} /> <Typography>View Timeline </Typography>{" "}
            <TiArrowSortedUp />
          </button>
          <GISTimelineDrawer
            isPlannerDrawerOpen={isPlannerDrawerOpen}
            isTimelineDrawerOpen={isTimelineDrawerOpen}
            setIsTimelineDrawerOpen={setIsTimelineDrawerOpen}
            drawerRef={drawerRef}
            tabValue={tabValue}
          />
        </>
      )}
      <GISDashboardSidebar
        togglePlannerDrawer={togglePlannerDrawer}
        isPlannerDrawerOpen={isPlannerDrawerOpen}
      />
    </div>
  );
};

export default GISDashboardMapScreen;
