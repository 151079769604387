import React from 'react';
import { Breadcrumbs as MUIBreadcrumbs, Link, Typography } from '@mui/material';

const Breadcrumbs = ({ path, onBreadcrumbClick }) => {
  const pathArray = path.split('/').filter(Boolean);

  return (
    <MUIBreadcrumbs aria-label="breadcrumb">
      <Link color="inherit" underline='none' className='breadCrumbs' onClick={() => onBreadcrumbClick('')}>
        Plans Changed
      </Link>
      {pathArray.map((segment, index) => {
        const segmentPath = pathArray.slice(0, index + 1).join('/');
        return (
          <Link key={segmentPath} underline='none' color="inherit" className='breadCrumbs' onClick={() => onBreadcrumbClick(segmentPath)}>
            {segment}
          </Link>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
