import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { fetchS3Data } from "../../Modals/LocationGroupModal/LocationGroupModal";
import GISDashboardMapScreen from "./GISDashboardMapScreen";
import {
  setBaseLayersGeojson,
  setWeekSummaryFiles,
} from "../../slices/gisDashboardSlice";
import {
  useLazyGetPropertyBaseLayersQuery,
  useLazyGetWeeklySummaryServiceWiseQuery,
} from "../../services/Properties";

const GISDashboardApi = () => {

  return (
    <>
      <GISDashboardMapScreen />
    </>
  );
};

export default GISDashboardApi;
