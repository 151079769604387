import React, { useRef, useState } from "react";
import AddServiceLayerModal from "../../Modals/AddServiceLayerModal/AddServiceLayerModal";
import { PiWrenchBold } from "react-icons/pi";
import "./PlanningDashboardSidebarSummary.css";
import { Box, Skeleton } from "@mui/material";
import { Typography, TableCell, styled, tableCellClasses } from "@mui/material";
import { getCustomColor } from "../../utils/theme";
import { useLocation } from "react-router-dom";
import {
  useGetRunPlannerSidebarDataQuery,
  useLazyGetPlanningStatusQuery,
  useStartPlanningMutation,
  useStopPlanningMutation,
} from "../../services/OpenApi";
import ServicesTable from "./Components/ServicesTable";
import CrewMemberTable from "./Components/CrewMemberTable";
import EquipmentTable from "./Components/EquipmentTable";
import { CiPlay1 } from "react-icons/ci";
import { FaRegStopCircle } from "react-icons/fa";
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#e0ffc4",
    color: "#8FE445",
    fontWeight: 600,
    fontSize: "12px",
    height: "10px",
    padding: "0px 6px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "13px",
  },
  whiteSpace: "nowrap",
}));

export const PlanningDashboardSidebarSummary = () => {
  const [isAddServiceLayerModalOpen, setIsAddServiceLayerModalOpen] =
    useState(false);
  const [isPlanningStarted, setIsPlanningStarted] = useState(false);
  const blackShade1 = getCustomColor("blackShade1");
  const user = JSON.parse(localStorage.getItem("user"));
  const locationQuery = useLocation();
  const searchParams = new URLSearchParams(locationQuery.search);
  const plannerId = searchParams.get("id");
  const [startPlanning, { isLoading: isStartPlanningLoading }] =
    useStartPlanningMutation();
  const [stopPlanning, { isLoading: isStopPlanningLoading }] =
    useStopPlanningMutation();
  const [getPlanningStatus, { isLoading: isGetPlanningStatusLoading }] =
  useLazyGetPlanningStatusQuery();
  const { data: runPlannerData, isLoading } = useGetRunPlannerSidebarDataQuery({
    plannerId: plannerId,
    orgName: user?.organization,
  });
  const servicesGetData = Array.isArray(
    runPlannerData?.data?.runPlannerServices
  )
    ? runPlannerData?.data?.runPlannerServices
    : [];
  const equipmentData = Array.isArray(runPlannerData?.data?.equipmentData)
    ? runPlannerData?.data?.equipmentData
    : [];
  const crewMembersList = Array.isArray(runPlannerData?.data?.crewMember)
    ? runPlannerData?.data?.crewMember
    : [];

  const [isEditServiceModalOpen, setIsEditServiceModalOpen] = useState(false);
  const tempRef = useRef(null);

  const handleStartPlanning = async () => {
    try {
      setIsPlanningStarted(true);
      const response = await startPlanning({
        v2PlannerId: runPlannerData?.data?.v2PlannerId,
        runPlannerId: runPlannerData?.data?.v2runPlannerId,
        parcelId: runPlannerData?.data?.parcelId,
        propertyName: runPlannerData?.data?.v2PlannerName,
        orgName: user?.organization,
      }).unwrap();
    } catch (err) {
      console.log(err);
    }
  };
  const handleStopPlanning = async () => {
    try {
      const response = await stopPlanning({
        runPlannerId: runPlannerData?.data?.v2runPlannerId,
        orgName: user?.organization,
      }).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      className="content"
      id="sidebarDrawer"
      style={{
        position: "relative",
        flex: 1,
      }}
      ref={tempRef}
    >
      <AddServiceLayerModal
        isOpen={isAddServiceLayerModalOpen}
        handleClose={() => setIsAddServiceLayerModalOpen(false)}
        ref={tempRef}
      />
      {/* Services Section */}
      <div className="propertyDetailsHeader">
        <div className="plannerPropertyDetails">
          {isLoading ? (
            <Skeleton variant="text" width={130} height={40} />
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <Typography
                  component="span"
                  fontWeight="600"
                  className="propertyTitleValue"
                  marginLeft="5px"
                  fontSize={14}
                  sx={{ color: blackShade1.main }}
                  variant="body1"
                >
                  {runPlannerData?.data?.v2PlannerName?.toUpperCase()} -{" "}
                  {runPlannerData?.data?.runPlannerName?.toUpperCase()}
                </Typography>
              </Box>
              {!isPlanningStarted ? (
                <button className="addButton" onClick={handleStartPlanning}>
                  Start Planning <CiPlay1 />
                </button>
              ) : (
                <button className="addButton" onClick={handleStopPlanning}>
                  Stop <FaRegStopCircle color="red" />
                </button>
              )}
            </Box>
          )}
        </div>
      </div>
      <div className="ServicesTableSection">
        <div className="sectionHeader">
          <div className="sectionHeaderLeft">
            <PiWrenchBold fontSize={26} />
            <Typography
              fontWeight="600"
              sx={{ color: blackShade1.shade1, fontSize: "16px" }}
              component="span"
            >
              Services Details
            </Typography>
          </div>
          <button
            className="addButton"
            onClick={() => setIsAddServiceLayerModalOpen(true)}
          >
            Add Service Layer
          </button>
        </div>

        <ServicesTable
          servicesGetData={servicesGetData}
          isDataLoading={isLoading}
          isEditServiceModalOpen={isEditServiceModalOpen}
          setIsEditServiceModalOpen={setIsEditServiceModalOpen}
          ref={tempRef}
        />
      </div>
      {/* Crew Member Section */}
      <CrewMemberTable
        crewMembersList={crewMembersList}
        isGetApiLoading={isLoading}
      />

      {/* Equipment Section */}
      <EquipmentTable
        equipmentData={equipmentData}
        isGetApiLoading={isLoading}
      />
      {/* custom backdrop because MUI backdrop covers the whole page */}
      {(isAddServiceLayerModalOpen || isEditServiceModalOpen) && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(50, 103, 3, 0.75)",
            zIndex: 100,
          }}
        />
      )}
    </div>
  );
};
