import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Dialog, DialogTitle, Divider } from "@mui/material";
import { useSelector } from "react-redux";
import { useUploadKMLFileMutation } from "../../services/OpenApi";
import { useGetOrderQuery } from "../../services/OpenApi";

const UploadModal = ({
  isOpen,
  setIsOpen,
  addOrUpdateCrewEquipment,
  editData,
  uploadData,
  site,
}) => {
  const [equipmentId, setEquipmentId] = useState(null);
  const [equipmentName, setEquipmentName] = useState("");
  const [productionRate, setProductionRate] = useState(0);
  const [transitionPace, setTransitionPace] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [file, setFile] = useState(null);
  const equipmentData = useSelector((state) => state.createCrew.equipments);
  const [uploadKMLFile, {isLoading}] = useUploadKMLFileMutation();
  const user = useSelector((state) => state.global.user);
  const handleCancel = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (editData) {
      setEquipmentId(editData[0].equipmentId);
      setEquipmentName(editData[0].description);
      setProductionRate(editData[0].productionRate);
      setTransitionPace(editData[0].transitionPace);
      setQuantity(editData[0].quantity);
    } else {
      setEquipmentId(null);
      setEquipmentName("");
      setProductionRate(0);
      setTransitionPace(0);
      setQuantity(1);
    }
  }, [editData, isOpen]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleAddOrUpdateCrewEquipment = async () => {
    if (!file) {
      console.error("No file selected.");
      return;
    }
    try {
      const response = await uploadKMLFile({
        file: file,
        parcelId: uploadData?.id,
        organisationName:  user?.organization,
        propertyName: uploadData?.site,
      }).unwrap();
      if(response){
        handleCancel();
        setFile(null);
      }
    } catch (error) {
      console.error("Failed to upload the file:", error);
    }
  };

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  return (
    <Dialog open={isOpen} fullWidth maxWidth="xs">
      <DialogTitle fontWeight={700} fontSize="24px" align="left">
        {editData ? "Update Equipment" : "Upload File"}
      </DialogTitle>
      <Divider />
      <div className="dialogContainer">
        <form>
          <div className="nameSkillsContainer">
            <div></div>
          </div>
          <Box
            sx={{
              // display: "flex",
              gap: "10px",
              justifyContent: "flex-end",
              mt: 2,
            }}
          >
            <div className="uploadButtonContainer mb-3">
              <input
                type="file"
                accept=".zip"
                ref={fileInputRef}
                // style={{ display: 'none' }}
                onChange={handleFileChange}
              />
              {/* <Button fullWidth size='small' variant='contained' onClick={handleButtonClick}>Upload</Button> */}
            </div>
            <div style={{ textAlign: "right", marginTop: "15px" }}>
              <Button variant="outlined" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                variant="contained"
                disabled={isLoading}
                onClick={() => {
                  handleAddOrUpdateCrewEquipment();
                }}
              >
                Upload
              </Button>
            </div>
          </Box>
        </form>
      </div>
    </Dialog>
  );
};

export default UploadModal;
