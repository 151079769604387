import React, { useEffect, useState } from "react";
import { ControlPosition, Map, MapControl } from "@vis.gl/react-google-maps";
import * as turf from "@turf/turf";
import { useDrawingManager } from "./useDrawingManager";
import { UndoRedoControl } from "./UndoRedoControl";
import { Box } from "@mui/material";
import SearchBar from "../../components/SearchBar/SearchBar";
import LocationCoordinateMarker from "./LocationCoordinateMarker";
import { useDispatch, useSelector } from "react-redux";
import {
  handleNextStep,
  setLocationName,
  setLocationPinCoordinate,
  setStepsLabels,
} from "../../slices/stepperFormSlice";
import ParkingsCoordinatesMarker from "./ParkingsCoordinatesMarker";
import ParkingModal from "../../Modals/ParkingModal/ParkingModal";
import axios from "axios";
import { useAddParkingMutation } from "../../services/OpenApi";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmationModal from "../../Modals/ConfirmationModal/ConfirmationModal";

const SurveyMap = () => {
  const {
    stepperForm: { activeStep, locationPinCoordinate, parkings },
  } = useSelector((state) => state);
  const [addParking, { isLoading }] = useAddParkingMutation();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [parkingPointDistance, setParkingPointDistance] = useState(false);
  const drawingManager = useDrawingManager({
    isVisible: activeStep === 2,
  });
  const {
    stepperForm: { parcelGeoJSON },
  } = useSelector((state) => state);
  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 12,
    width: 800,
    height: 400,
    bearing: 0,
    disableDefaultUI: true,
    mapTypeId: 'hybrid',
    zoomControl: true,
    zoomControlOptions: {
      position: ControlPosition?.LEFT_BOTTOM,
    },
    scaleControl: true,
    streetViewControl: false,
    fullscreenControl: false,
  });
  const dispatch = useDispatch();
  const params = useParams();
  const user = useSelector((state) => state.global.user);
  const [isParkingModalOpen, setIsParkingModalOpen] = useState();
  const [parkingCoordinates, setParkingCoordinates] = useState();
  const [parkingAddress, setParkingAddress] = useState();
  const onPlaceSelected = (place) => {
    if (place.geometry && place.geometry.location) {
      dispatch(
        setLocationPinCoordinate({
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        })
      );
      setViewport((prev) => ({
        ...prev,
        center: {
          lat: place?.geometry?.location?.lat(),
          lng: place?.geometry?.location?.lng(),
        },
        zoom: 18,
      }));
      dispatch(setLocationName(place?.formatted_address));
      dispatch(
        setStepsLabels({ id: 1, description: place?.formatted_address })
      );
      dispatch(handleNextStep());
    }
  };

  useEffect(() => {
    if (locationPinCoordinate) {
      setViewport((prev) => ({
        ...prev,
        center: locationPinCoordinate,
        zoom: 16,
      }));
      fetchAddress(locationPinCoordinate).then((placeName) => {
        dispatch(setLocationName(placeName));
        dispatch(setStepsLabels({ id: 1, description: placeName }));
      });
    }
  }, [locationPinCoordinate]);

  const searchBoxContainerStyle = {
    position: "absolute",
    top: "9vh",
    left: "50%",
    transform: "translateX(-50%)",
    width: "300px",
    zIndex: "10", // Ensure it is on top of the map
  };

  /**
   * Calculate the distance between two points in miles.
   * @param {Array<number>} point1 - The first point [longitude, latitude].
   * @param {Array<number>} point2 - The second point [longitude, latitude].
   * @returns {number} - The distance between the two points in miles.
   */
  const calculateDistanceBetweenPoints = (point1, point2) => {
    const from = turf.point(point1);
    const to = turf.point(point2);
    const options = { units: "miles" };
    const distance = turf.distance(from, to, options);
    return distance;
  };

  const handleMapClick = (event) => {
    if (event && activeStep === 1) {
      dispatch(
        setLocationPinCoordinate({
          lat: event?.detail?.latLng?.lat,
          lng: event?.detail?.latLng?.lng,
        })
      );
    }
    if (event && activeStep === 3) {
      if (parkings.length < 5) {
        setParkingCoordinates({
          lat: event?.detail?.latLng?.lat,
          lng: event?.detail?.latLng?.lng,
        });
        const distance = calculateDistanceBetweenPoints(
          [locationPinCoordinate.lng, locationPinCoordinate.lat],
          [event?.detail?.latLng?.lng, event?.detail?.latLng?.lat]
        );

        setParkingPointDistance(distance?.toFixed(2));

        if (distance < 1) {
          setIsParkingModalOpen(true);
        } else {
          setIsConfirmationModalOpen(true);
        }
      } else {
        toast.warn("Maximum 5 parkings can be added");
      }
    }
  };

  const handleAddParking = () => {
    setIsConfirmationModalOpen(false);
    setIsParkingModalOpen(true);
  };

  const addParkingFunc = async (parkingName, parkingType, address) => {
    const newParking = {
      parkingName: parkingName,
      parkingType: parkingType,
      address: address,
      points: {
        lat: parkingCoordinates?.lat,
        lon: parkingCoordinates?.lng,
      },
    };
    try {
      await addParking({
        orderId: params?.id,
        orgName: user?.organization,
        content: newParking,
      });
    } catch (err) {
      console.log("error => ", err);
    }
  };

  const fetchAddress = async (coordinates) => {
    try {
      const response = await axios.get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${coordinates.lng},${coordinates.lat}.json?access_token=${process.env.REACT_APP_MAPBOX_KEY}`
      );
      const data = response.data;
      if (data.features.length > 0) {
        const placeName = data.features[0].place_name; // This is a full text description of the place
        return placeName;
      } else {
        return "No address found";
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (parkingCoordinates) {
      fetchAddress(parkingCoordinates).then((placeName) => {
        setParkingAddress(placeName);
      });
    }
  }, [parkingCoordinates]);
  return (
    <>
      {!locationPinCoordinate && (
        <Box sx={searchBoxContainerStyle}>
          <SearchBar onPlaceSelected={onPlaceSelected} />
        </Box>
      )}
      <Map
        gestureHandling={"greedy"}
        {...viewport}
        zoom={viewport?.zoom}
        onCameraChanged={(v) => setViewport(v)}
        center={viewport?.center}
        onClick={(event) => handleMapClick(event)}
        mapId="satellite"
        defaultBounds={{
          south: 40.5,
          west: -74.2,
          north: 40.9,
          east: -73.8,
        }}
      >
        {locationPinCoordinate && (
          <LocationCoordinateMarker coordinate={locationPinCoordinate} />
        )}
        {parkings.length > 0 && activeStep > 2 && (
          <ParkingsCoordinatesMarker parkings={parkings} />
        )}
        {activeStep === 2 && (
          <MapControl position={ControlPosition.LEFT_TOP}>
            <UndoRedoControl
              drawingManager={drawingManager}
              readOnly={false}
              parcelGeoJSON={parcelGeoJSON}
            />
          </MapControl>
        )}
        {activeStep > 2 && (
          <MapControl position={ControlPosition.LEFT_TOP}>
            <UndoRedoControl
              drawingManager={drawingManager}
              readOnly={true}
              parcelGeoJSON={parcelGeoJSON}
            />
          </MapControl>
        )}
        {isParkingModalOpen && (
          <ParkingModal
            isOpen={isParkingModalOpen}
            setIsOpen={setIsParkingModalOpen}
            parkingAddressVal={parkingAddress}
            submitFunction={addParkingFunc}
            parkingCoordinates={parkingCoordinates}
          />
        )}
      </Map>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        body={`Parking point is ${parkingPointDistance} miles away from the parcel. Do you still want to add it?`}
        PrimaryButtonTitle="Confirm"
        handleOk={handleAddParking}
      />
    </>
  );
};

export default SurveyMap;
