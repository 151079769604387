import React, { useEffect } from "react";
import "./Properties.css";
import ParcelTable from "./Components/ParcelTable";
import SavedPlans from "./Components/SavedPlans";
import { useNavigate } from "react-router-dom";

const Properties = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.organization) {
      navigate("/newuser", { replace: true });
    }
  }, []);

  return (
    <div className="propertiesContainer">
      <SavedPlans />
      <ParcelTable />
    </div>
  );
};

export default Properties;
