import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider,
  TextField,
  Skeleton,
  InputAdornment,
  IconButton,
  Stack,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { getCustomColor } from "../../utils/theme";
import { FaMinusCircle } from "react-icons/fa";
import {
  useGetServicesQuery,
  useGetCompleteBeforeOptionsQuery,
  useGetLayersQuery,
} from "../../services/OpenApi";
import { MdClose } from "react-icons/md";
import ColorPickerButton from "../../components/Buttons/ColorPickerButton";

const AddServiceStep = ({
  activeStep = 0,
  handleNext = () => {},
  handleLayerSelection = () => {},
  selectedServiceId,
  savedLayers,
  selectedCompleteBefore,
  selectedIsAddNewService,
  selectedNewServiceName,
  editServiceData,
  color,
  setColor
}) => {
  const locationQuery = useLocation();
  const searchParams = new URLSearchParams(locationQuery.search);
  const v2PlannerId = searchParams.get("v2PlannerId");
  const plannerId = searchParams.get("id");
  const blackShade1 = getCustomColor("blackShade1");
  const greenShade1 = getCustomColor("greenShade1");
  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors },
  } = useForm();

  const [selectedLayers, setSelectedLayers] = useState([]);
  const [isAddNewService, setIsAddNewService] = useState(false);
  const [newServiceName, setNewServiceName] = useState(null);
  const [servicesDropdownData, setServicesDropdownData] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));

  // Use RTK Query hooks
  const { data: servicesData, isLoading: loadingServices } =
    useGetServicesQuery({ id: plannerId, orgName: user?.organization });
  const { data: completeBeforeData, isLoading: loadingCompleteBefore } =
    useGetCompleteBeforeOptionsQuery({
      id: plannerId,
      orgName: user?.organization,
    });
  const { data: layersData, isLoading: loadingLayers } = useGetLayersQuery({
    id: v2PlannerId,
    orgName: user?.organization,
  });
  const layers = layersData?.data?.map((layer) => layer.coreLayerName) || [];
  const completeBeforeOptions = completeBeforeData?.data || [];

  const onCheckboxChange = (layer) => {
    const updatedLayers = selectedLayers.includes(layer)
      ? selectedLayers.filter((l) => l !== layer)
      : [...selectedLayers, layer];

    setSelectedLayers(updatedLayers);
    handleLayerSelection(updatedLayers);
  };

  const onSubmit = async (data) => {
    const stepValid = await trigger(["service", "completeBefore"]);
    if (true) {
      handleNext({
        coreServiceId: data.service, // Pass selected service ID as coreServiceId
        completeBefore: data.completeBefore,
        selectedLayers: selectedLayers,
        isAddNewService: isAddNewService,
        newServiceName: newServiceName,
        from: "service",
      });
    }
  };

  useEffect(() => {
    if (servicesData?.data) {
      setServicesDropdownData(servicesData?.data);
    }
  }, [servicesData]);

  useEffect(() => {
    setValue("service", selectedServiceId);
    setValue("completeBefore", selectedCompleteBefore);
    setSelectedLayers(savedLayers);
    setIsAddNewService(selectedIsAddNewService);
    setNewServiceName(selectedNewServiceName);
    
      if (editServiceData) {
        const exists = servicesDropdownData.some(
          (service) => service.id === editServiceData.coreServiceId
        );
      
        if (!exists) {
          setServicesDropdownData((prev) => [
            ...prev,
            {
              id: editServiceData.coreServiceId,
              serviceName: editServiceData.coreServiceName,
            },
          ]);
        }
      }
  }, [selectedServiceId, servicesDropdownData, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{flex: 1, position: "relative"}}>
      {/* Service selection */}
      {!isAddNewService && (
          <Controller
          name="service"
          control={control}
          rules={{ required: "Service is required" }}
          render={({ field }) => (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "5px 0 -10px 0",
                }}
              >
                <Typography
                  fontWeight="600"
                  sx={{ color: blackShade1.light }}
                  variant="body1"
                >
                  Select a Service
                </Typography>
                <Typography
                  fontWeight="500"
                  sx={{ color: greenShade1.shade1, cursor: "pointer" }}
                  variant="body1"
                  onClick={() => setIsAddNewService(true)}
                >
                  Add new service
                </Typography>
              </Box>
              <Stack
                spacing={{ xs: 1 }}
                direction="row"
                useFlexGap
                alignItems="flex-end"
                margin="10px 0 10px 0"
              >
              <FormControl
                fullWidth
                error={Boolean(errors.service)}
              >
                <Select
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    trigger("service");
                  }}
                  value={field.value || ""}
                  size="small"
                  error={!!errors.service}
                  disabled={loadingServices} // Disable while loading
                >
                  {servicesDropdownData.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.serviceName}
                    </MenuItem>
                  ))}
                </Select>
                {errors.service && (
                  <FormHelperText>{errors.service.message}</FormHelperText>
                )}
              </FormControl>
              <ColorPickerButton value={color} setValue={setColor} />
             </Stack>
            </>
          )}
        />
      )}
      <Box sx={{ display: "flex", justifyContent: "end" }}></Box>
      {isAddNewService && (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              margin: "5px 0 -10px 0",
            }}
          >
            <Typography
              fontWeight="600"
              sx={{ color: blackShade1.light }}
              variant="body1"
            >
              Add New Service
            </Typography>
            <Box
              sx={{ cursor: "pointer" }}
              onClick={() => setIsAddNewService(false)}
            >
              <FaMinusCircle color="red" />
            </Box>
          </Box>

          <TextField
            size="small"
            fullWidth
            required
            id="serviceName"
            name="serviceName"
            margin="normal"
            value={newServiceName}
            onChange={(e) => setNewServiceName(e.target.value)}
          />
        </>
      )}

      {/* Complete Before selection */}
      <Controller
        name="completeBefore"
        control={control}
        render={({ field }) => (
          <>
            <Typography
              fontWeight="600"
              sx={{ mb: -2, color: blackShade1.light }}
              variant="body1"
            >
              Complete Before
            </Typography>
            <FormControl
              fullWidth
              margin="normal"
              error={Boolean(errors.completeBefore)}
            >
              <Select
                {...field}
                value={field.value || ""}
                onChange={(e) => {
                  field.onChange(e);
                }}
                size="small"
                disabled={loadingCompleteBefore} // Disable while loading
                endAdornment={
                  field.value && (
                    <InputAdornment position="end" sx={{marginRight: "10px"}}>
                      <IconButton
                        onClick={() => {
                          setValue("completeBefore", "");
                        }}
                        size="small"
                      >
                        <MdClose />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {completeBeforeOptions.length === 0 ? (
                  <MenuItem disabled>No options available</MenuItem>
                ) : (
                  completeBeforeOptions.map((option, index) => (
                    <MenuItem key={index} value={option.v2ServiceId}>
                      {option?.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </>
        )}
      />

      {/* Layers selection */}
      <Typography
        fontWeight="600"
        sx={{ color: blackShade1.light }}
        variant="body1"
      >
        Select Layers
      </Typography>
      <Box className="layersContainer" sx={{ mt: 1 }}>
        {loadingLayers
          ? Array.from({ length: 5 }).map((_, index) => (
              <Skeleton
                key={index}
                height={26}
                sx={{ mb: 1 }}
                variant="rectangular"
              />
            ))
          : layers.map((layer, index) => (
              <Box key={index}>
                <Box
                  className="layerContainer"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 10px 0 0",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Checkbox
                      size="small"
                      checked={selectedLayers.includes(layer)}
                      onChange={() => onCheckboxChange(layer)}
                    />
                    <Typography
                      fontWeight="500"
                      sx={{ color: blackShade1.light }}
                      variant="body1"
                    >
                      {layer}
                    </Typography>
                  </Box>
                  <Box>
                    <div
                      className={`Plannercolor ${layer.replace(" ", "_")}`}
                    />
                  </Box>
                </Box>
                {index < layers.length - 1 && <Divider />}
              </Box>
            ))}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end", position: "absolute", bottom: 0, right: 0 }}>
        <Button size="small" variant="contained" type="submit">
          Next
        </Button>
      </Box>
    </form>
  );
};

AddServiceStep.propTypes = {
  activeStep: PropTypes.number,
  handleNext: PropTypes.func,
  handleLayerSelection: PropTypes.func,
};

export default AddServiceStep;
