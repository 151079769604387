import React, { useEffect, useState } from "react";
import { GeoJsonLayer } from "@deck.gl/layers";
import { getServiceColorRgb, hexToRgb } from "../../utils/common";
import useViewportWidth from "../../Hooks/useViewportWidth";
import { DeckGlOverlay } from "../../utils/deckgl-overlay";

function getDeckGlLayers(data, setHoveredData, hoveredGeoJson) {
  if (!data) return [];

  return [
    new GeoJsonLayer({
      id: "geojsonBase",
      data: data,
      stroked: true,
      filled: true,
      getLineWidth: 0.2,
      opacity: hoveredGeoJson ? 0.05 : 0.5,
      getFillColor: (f) => {
        return f?.properties?.fill ?  hexToRgb(f?.properties?.fill) : getServiceColorRgb(f?.properties?.layerType);
      },
      getLineColor: (f) => {
        return f?.properties?.stroke ?  hexToRgb(f?.properties?.stroke) : getServiceColorRgb(f?.properties?.layerType)
      },
      lineWidthMinPixels: 2,
      parameters: {
        depthTest: false,
      },
      pickable: true,
      onHover: (info, event) => {
        if (info && info?.object) {
          setHoveredData(info?.object);
        } else {
          setHoveredData(null);
        }
      }
    }),
  ];
}

const BaseUploadedLayers = ({ geoJson, layerTypes, setHoveredData, hoveredGeoJson }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [filteredData, setFilteredData] = useState(null);
  const width = useViewportWidth();

  useEffect(() => {
    if (layerTypes) {
      const filteredFeatures = geoJson.features.filter(
        (feature) => feature.properties.layerType && layerTypes[feature.properties.layerType]
      );
      setFilteredData({ ...geoJson, features: filteredFeatures });
    } else {
      setFilteredData(null);
    }
  }, [layerTypes, geoJson]);

  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(filteredData, setHoveredData, hoveredGeoJson)}
      pickable={true}
      typedArrayManagerProps={
        width < 800 ? { overAlloc: 1, poolSize: 0 } : null
      }
      isVisible={isVisible}
    />
  );
};

export default BaseUploadedLayers;
