export const ROUTES = {
    LOGIN: "/login",
    REGISTER: "/register",
    MAP: "/map",
    CREWCREATION: "/createCrew",
    SURVEY: "/survey",
    NEWUSER: "/newuser",
    DASHBOARD: "/dashboard",
    UPLOAD: "/upload",
    HOME: "/",
    PARCEL: "/parcel/:id",
    SUMMARY: "/summary/:id",
    PLANNING : "/planning/:id",
    VERIFYEMAIL: "/verify-email/:id",
    RESETPASSWORD: "/forgot-password",
    RESETPASSWORDLINK: "/reset-password/uid/:uid/token/:token",
    MAPS :  "/maps",
    SERVICES :  "/addServices",
}

export const APIENDPOINTS = {
    LOGIN: "v3/login",
    REGISTER: "v3/register",
    RESETPASSWORD: "v3/reset-password",
    RESETPASSWORDCONFIRM: "v3/reset-password-confirm",
    VERIFYEMAIL: "v3/verify-email",
    ORGANIZATION: "v3/organizations",
    REFRESHTOKEN: "v3/refresh-jwt-token",  
}