import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  convertProductionUnitRate,
  convertTransitionPace,
} from "../../utils/common";
import { getCustomColor } from "../../utils/theme";
import { equipmentsDummy } from "../../utils/defaultVars";
import { useLazyGetLayerEquipmentsQuery } from "../../services/OpenApi";
import { toast } from "react-toastify";

const AddEquipmentStep = ({ activeStep, handleBack, handleNext, selectedEquipment }) => {
  const [defaultEquipments, setDefaultEquipments] = useState([]);
  const [getLayerEquipments, { isLoading }] = useLazyGetLayerEquipmentsQuery();
  const blackShade1 = getCustomColor("blackShade1");
  const greenShade1 = getCustomColor("greenShade1");
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      equipments: [],
    },
  });

  const onSubmit = async (data) => {
    let stepValid = false;
    stepValid = await trigger(["equipments"]);
    if (stepValid) {
      handleNext({
        equipment: data?.equipments, // Pass selected service ID as coreServiceId
        from: "equipment",
      });
    }
  };

  useEffect(() => {
    if(!defaultEquipments.length) return;
    if (
      selectedEquipment
    ) {
      let temp = selectedEquipment.map(
        (equipment) => {
          const matchedEquipment = defaultEquipments.find((x) => x.v2CoreEquipmentId === equipment.v2CoreEquipmentId)
          return {
            ...equipment,
            productionUnits: matchedEquipment?.productionUnits || [],
            transitionPaceUnits: matchedEquipment?.transitionPaceUnits || [],
            equipmentName: matchedEquipment?.equipmentName || "",
          }
        }
      );
      setValue("equipments", temp);
    }
  }, [selectedEquipment, setValue, defaultEquipments]);

  const fetchEquipments = async () => {
    try {
      const response = await getLayerEquipments();
      if (response?.data) {
        const result = response?.data?.data?.map((equipment) => {
          return {
            ...equipment,
            productionUnits: [
              {
                name: "sqft/hr",
                value: "SQUARE_FEET_PER_HOUR",
              },
              {
                name: "acre/hr",
                value: "ACRES_PER_HOUR",
              },
              {
                name: "Hectare/hr",
                value: "HECTARE_PER_HOUR",
              },
            ],
            transitionPaceUnits: [
              {
                name: "ft/hr",
                value: "FEET_PER_HOUR",
              }
            ],
          };
        });
        setDefaultEquipments(result);
      } else if (response?.error) {
        toast.error(response?.error?.error || response?.error?.data?.message, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (err) {
      toast.error("something went wrong", {
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  };

  useEffect(() => {
    fetchEquipments();
  }, []);

  // const handleFieldChange = (index, fieldName, value) => {
  //   setValue(`serviceEquipment.${index}.${fieldName}`, value, {
  //     shouldValidate: true,
  //   });
  // };

  const handleEquipmentChange = (e, field) => {
    const clickedItem = e.target.value[e.target.value.length - 1];
    const isSelected = (field.value || []).some(
      (item) => item.v2CoreEquipmentId === clickedItem.v2CoreEquipmentId
    );

    const newValue = isSelected
      ? field.value.filter(
          (item) => item.v2CoreEquipmentId !== clickedItem.v2CoreEquipmentId
        )
      : [
          ...(field.value || []),
          defaultEquipments.find(
            (equipment) =>
              equipment.v2CoreEquipmentId === clickedItem.v2CoreEquipmentId
          ),
        ];
    field.onChange(newValue);
    setTimeout(() => trigger("equipments"), 0);
  };

  const handleProductionUnitChange = (index, unit) => {
    const currentProductionRate = getValues(
      `equipments[${index}].productionRate`
    );
    const currentProductionRateUnit = getValues(
      `equipments[${index}].productionRateUnit`
    );

    const newRate = convertProductionUnitRate(
      currentProductionRate,
      currentProductionRateUnit,
      unit
    );

    setValue(`equipments[${index}].productionRate`, newRate);
    setValue(`equipments[${index}].productionRateUnit`, unit);
  };

  const handleTransitionUnitChange = (index, unit) => {
    const currentTransitionPace = getValues(
      `equipments[${index}].transitionPace`
    );
    const currentTransitionPaceUnit = getValues(
      `equipments[${index}].transitionPaceUnit`
    );

    const newPace = convertTransitionPace(
      currentTransitionPace,
      currentTransitionPaceUnit,
      unit
    );

    setValue(`equipments[${index}].transitionPace`, newPace);
    setValue(`equipments[${index}].transitionPaceUnit`, unit);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}
      style={{flex: 1, position: "relative", display: "flex", flexDirection: "column"}}
    >
      <Controller
        name="equipments"
        control={control}
        rules={{ required: "Equipment is required" }}
        render={({ field }) => (
          <FormControl
            fullWidth
            margin="normal"
            error={Boolean(errors.equipments) && (field.value?.length || 0) < 1}
          >
            <InputLabel size="small">Select Equipment</InputLabel>
            <Select
              multiple
              input={<OutlinedInput label="Select Equipment" />}
              {...field}
              value={field.value || []}
              onChange={(e) => handleEquipmentChange(e, field)}
              size="small"
              label="Select Equipment"
              error={
                Boolean(errors.equipments) && (field.value?.length || 0) < 1
              }
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip
                      key={value.equipmentName}
                      label={value.equipmentName}
                    />
                  ))}
                </Box>
              )}
            >
              {defaultEquipments &&
                defaultEquipments.map((equipment) => (
                  <MenuItem
                    key={equipment.v2CoreEquipmentId}
                    value={equipment}
                    selected={(field.value || []).some(
                      (selected) =>
                        selected.v2CoreEquipmentId ===
                        equipment.v2CoreEquipmentId
                    )}
                    style={{
                      backgroundColor: (field.value || []).some(
                        (selected) =>
                          selected.v2CoreEquipmentId ===
                          equipment.v2CoreEquipmentId
                      )
                        ? "rgba(0, 0, 0, 0.08)"
                        : "transparent",
                    }}
                  >
                    {equipment.equipmentName}
                  </MenuItem>
                ))}
            </Select>

            {errors.equipments && (
              <FormHelperText>{errors.equipments.message}</FormHelperText>
            )}
          </FormControl>
        )}
      />
      <Box className="equipmentWrapper">
      {(watch("equipments") || []).map((equipment, index) => (
        <Box
          className="equipmentContainer"
          sx={{
            border: `solid 1px ${greenShade1.main}`,
          }}
          key={equipment.v2CoreEquipmentId}
        >
          <Typography
            fontWeight={"700"}
            sx={{ color: greenShade1.shade1 }}
            component="span"
            variant="body1"
          >
            {equipment.equipmentName}
          </Typography>
          <div className="equipmentDataContainer">
              <div className="serviceInputContainer">
                <Controller
                  name={`equipments[${index}].productionRate`}
                  control={control}
                  rules={{
                    required: "Production Rate is required",
                    min: {
                      value: 0,
                      message: "Minimum value should be 0",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="number"
                      label="Production Rate"
                      error={!!errors.equipments?.[index]?.productionRate}
                      helperText={
                        errors.equipments?.[index]?.productionRate?.message ||
                        ""
                      }
                      InputProps={{
                        inputProps: {
                          min: 0,
                          step: 0.00000000000000000001,
                        },
                      }}
                      sx={{width: "100%"}}
                    />
                  )}
                />
                <Controller
                  name={`equipments[${index}].productionRateUnit`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      size="small"
                      sx={{ width: "120px" }}
                      onChange={(e) =>
                        handleProductionUnitChange(index, e.target.value)
                      }
                    >
                      {equipment.productionUnits.map((unit) => (
                        <MenuItem key={unit.value} value={unit.value}>
                          {unit.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
            </div>
              <div className="serviceInputContainer">
                <Controller
                  name={`equipments[${index}].transitionPace`}
                  control={control}
                  rules={{
                    required: "Top Speed is required",
                    min: {
                      value: 1,
                      message: "Minimum value should be 1",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      size="small"
                      type="number"
                      label="Top Speed"
                      error={!!errors.equipments?.[index]?.transitionPace}
                      helperText={
                        errors.equipments?.[index]?.transitionPace?.message ||
                        ""
                      }
                      InputProps={{
                        inputProps: {
                          min: 1,
                          step: 0.00000000000000000001,
                        },
                      }}
                      sx={{width: "100%"}}
                    />
                  )}
                />
                <Controller
                  name={`equipments[${index}].transitionPaceUnit`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      size="small"
                      sx={{ width: "120px" }}
                      onChange={(e) =>
                        handleTransitionUnitChange(index, e.target.value)
                      }
                    >
                      {equipment.transitionPaceUnits.map((unit) => (
                        <MenuItem key={unit.value} value={unit.value}>
                          {unit.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
              </div>
          </div>
        </Box>
      ))}
      </Box>
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          background: "white",
          width: "100%",
          gap: "10px",
          bottom: 0
        }}
      >
        <Button size="small" variant="contained" onClick={handleBack}>
          Prev
        </Button>
        <Button size="small" variant="contained" type="submit">
          Next
        </Button>
      </Box>
    </form>
  );
};

export default AddEquipmentStep;
