import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { GeoJsonLayer, PathLayer } from "@deck.gl/layers";
import useViewportWidth from "../../../Hooks/useViewportWidth";
import { crewMemberColors, hexToRgb } from "../../../utils/common";
import { PathStyleExtension } from "@deck.gl/extensions";

function getDeckGlLayers(data, lineData) {
  if (!data) return [];
  return [
    new GeoJsonLayer({
      id: "wayPointsLayer",
      data: data,
      pointType: "circle+text",
      getPosition: (d) => d.geometry.coordinates,
      getFillColor: [0, 0, 0],
      getLineColor: (d) => [0, 0, 0],
      stroked: true,
      iconBillboard: true,
      getPointRadius: 1,
      pointSizeScale: 1,
      pointRadiusMaxPixels: 1,
      pickable: false,
    }),
    new PathLayer({
      id: "dashed-waypoint-path-layer",
      data: lineData,
      getPath: (d) => [d.source, d.target], // Adjust according to your data structure
      getColor: (d) => d.color,
      getWidth: 0.2,
      getDashArray: [5, 5],
      visible: true,
      extensions: [new PathStyleExtension({ dash: true })],
      dashJustified: true,
      dashGapPickable: true,
      widthMinPixels: 2,
      pickable: false,
    }),
  ];
}

const UploadedWayPointsMarkers = ({}) => {
  const [numberMarkersJson, setNumberMarkersJson] = useState(null);
  const [lineData, setLineData] = useState(null);
  const uploadedWayPointsData = useSelector(
    (state) => state.global.uploadedWayPointsData
  );
  const [isVisible, setIsVisible] = useState(true);
  const width = useViewportWidth();

  const dummyData = [
    [
      { lon: -78.8432146427, lat: 35.81814601 },
      { lon: -78.8429268904, lat: 35.8180786373 },
      { lon: -78.8430235469, lat: 35.8178976487 },
      { lon: -78.8434286741, lat: 35.8179968451 },
    ],
    [
      { lon: -78.8432146427, lat: 35.81814601 },
      { lon: -78.8429268904, lat: 35.8180786373 },
      { lon: -78.8430235469, lat: 35.8178976487 },
      { lon: -78.8434286741, lat: 35.8179968451 },
    ],
  ];

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (uploadedWayPointsData) {
      const features = [];
      uploadedWayPointsData?.map((firstLevelArray) => {
        firstLevelArray?.map((data) => {
          const feature = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [parseFloat(data.lon), parseFloat(data.lat)],
            },
            properties: {
              id: `${data.lon}`,
            },
          };
          features?.push(feature);
        });
      });
      setNumberMarkersJson({
        type: "FeatureCollection",
        features: features,
      });
    }
  }, [uploadedWayPointsData]);

  useEffect(() => {
    if (uploadedWayPointsData) {
      const transformedArray = [];

      uploadedWayPointsData.forEach((wayPoints, index) => {
        let lineColor = hexToRgb(crewMemberColors[index]);

        wayPoints.forEach((wayPointData, index) => {
          if (wayPoints.length -1 > index) {

            const transformedObject = {
              source: [
                parseFloat(wayPoints[index]?.lon),
                parseFloat(wayPoints[index]?.lat),
              ],
              target: [
                parseFloat(wayPoints[index + 1]?.lon),
                parseFloat(wayPoints[index + 1]?.lat),
              ],
              color: lineColor,
            };

            transformedArray.push(transformedObject);
          }
        });
      });

      setLineData(transformedArray);
    }
  }, [uploadedWayPointsData]);

  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(numberMarkersJson, lineData)}
      typedArrayManagerProps={
        width < 800 ? { overAlloc: 1, poolSize: 0 } : null
      }
      isVisible={isVisible}
    />
  );
};

export default UploadedWayPointsMarkers;
