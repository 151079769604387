import { Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ParkingCard from "./ParkingCard";
import { handleNextStep, setParcelGeoJSON, setParkings } from "../../../slices/stepperFormSlice";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";
import ParkingModal from "../../../Modals/ParkingModal/ParkingModal";
import {
  useDeleteParkingMutation,
  useEditParkingMutation,
  useGetParkingsQuery,
  useLazyGetParkingsQuery,
  useOrderStatusMutation,
} from "../../../services/OpenApi";
import { useParams } from "react-router-dom";
import { getCustomColor } from "../../../utils/theme";
import { MdInfo } from "react-icons/md";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AddParkingForm = () => {
  const {
    stepperForm: { parkings },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const blackShade1 = getCustomColor("blackShade1");
  const params = useParams();
  const user = useSelector((state) => state.global.user);
  const [getParkings] = useLazyGetParkingsQuery();
  const { data: parkingsData } = useGetParkingsQuery({
    orderId: params?.id,
    orgName: user?.organization,
  });
  const [deleteParking] = useDeleteParkingMutation();
  const [orderStatus, { isLoading }] = useOrderStatusMutation();
  const [editParking] = useEditParkingMutation();
  const [modalOpenForId, setModalOpenForId] = useState(null);
  const [isParkingModalOpen, setIsParkingModalOpen] = useState(false);
  const [editParkingData, setEditParkingData] = useState(null);

  const handleEdit = (id) => {
    const parking = parkings.filter((parking) => parking.id === id);
    setEditParkingData(parking[0]);
    setIsParkingModalOpen(true);
  };

  const handleDelete = (id) => {
    setModalOpenForId(id);
  };

  const handleRemoveParking = (id) => {
    deleteParking({ id: id, orgName: user?.organization });
    setModalOpenForId(null);
  };

  const editParkingFunc = async (
    parkingName,
    parkingType,
    address,
    parkingCoordinates,
    id
  ) => {
    const updatedParking = parkings.map((parking) => {
      if (parking?.id === id) {
        return {
          ...parking,
          parkingName: parkingName,
          parkingType: parkingType,
          address: address,
          points: {
            lat: parkingCoordinates?.lat,
            lon: parkingCoordinates?.lng,
          },
        };
      }
      return parking;
    });
    try {
      await editParking({
        id: id,
        orgName: user?.organization,
        content: {
          parkingName: parkingName,
          parkingType: parkingType,
          address: address,
          points: {
            lat: parkingCoordinates?.lat,
            lon: parkingCoordinates?.lng,
          },
        },
      });
    } catch (err) {
      console.log("error => ", err);
    }
  };


  useEffect(() => {
    const fetchParkings = async () => {
      try {
        const response = await getParkings({
          orderId: params?.id,
          orgName: user?.organization,
        }).unwrap();

        if (response) {
          dispatch(setParkings(response?.data));
        }
      } catch (err) {
        console.error("Error fetching parkings:", err);
        toast.error("Failed to load parkings data.", {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    };

    fetchParkings();
  }, [getParkings, params?.id, user?.organization, dispatch]);


  useEffect(() => {
    if (parkingsData) {
      dispatch(setParkings(parkingsData?.data));
    }
  }, [parkingsData, dispatch]);

  const onSubmitParkings = async () => {
    try {
      const response = await orderStatus({
        orderId: params?.id,
        orgName: user?.organization,
        status: "PARKING",
      }).unwrap();

      if (response?.data) {
        toast.success("Added successfully", {
          autoClose: 1000,
          hideProgressBar: true,
        });
        navigate(`/summary/${params?.id}`);
      } else if (response?.error) {
        toast.error(response.error?.error || response?.error, {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    } catch (err) {
      console.error("Failed to submit parking:", err);
      toast.error("Something went wrong, please try again.", {
        autoClose: 2000,
        hideProgressBar: true,
      });
    }
  };

  return (
    <div className="StepsFormContainer">
      <h3 className="StepsFormContainerHeading">Add Parkings</h3>
      <div className="parkingCardContent">
        <Typography
          sx={{ color: blackShade1.main }}
          display="flex"
          alignItems="center"
          fontSize="13px"
          variant="body2"
        >
          <MdInfo color="grey" fontSize={16} /> Click on map to add parkings.
        </Typography>
      </div>
      {parkings?.map((parking, index) => (
        <>
          <ParkingCard
            key={index}
            parking={parking}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
          {modalOpenForId === parking?.id && (
            <ConfirmationModal
              isOpen={modalOpenForId}
              setIsOpen={() => setModalOpenForId(null)}
              title="Remove Parking"
              body="Are you sure you want to remove this parking?"
              handleOk={() => handleRemoveParking(parking.id)}
            />
          )}
          {isParkingModalOpen && (
            <ParkingModal
              isOpen={isParkingModalOpen}
              setIsOpen={setIsParkingModalOpen}
              submitFunction={editParkingFunc}
              editData={editParkingData}
            />
          )}
        </>
      ))}

      <Button
        type="submit"
        fullWidth
        disabled={parkings.length === 0 || isLoading}
        variant="contained"
        onClick={onSubmitParkings}
        sx={{ mt: 1, mb: 2 }}
      >
        {isLoading ? <CircularProgress size={24} /> : "Confirm"}
      </Button>
    </div>
  );
};

export default AddParkingForm;
