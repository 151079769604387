import React, { useState, useEffect, useMemo } from "react";
import { Typography, Box, Button, Checkbox, CircularProgress, Stack, Chip } from "@mui/material";
import { FaCaretRight, FaCaretDown } from "react-icons/fa6";
import {
  useCreateRunPlannerServiceMutation,
  useAddCoreServiceMutation,
 useUpdateRunPlannerServiceMutation} from "../../services/OpenApi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { setSelectedPolygonIds } from "../../slices/globalSlice";
import { getCustomColor } from "../../utils/theme";

const DetailSectionStep = ({
  selectedLayers,
  planner,
  handleBack,
  coreServiceId,
  handleClose,
  selectedEquipment,
  selectedCompleteBefore,
  selectedIsAddNewService,
  selectedNewServiceName,
  isEditMode = false,
  selectedParents,
  setSelectedParents,
  selectedParentsFlag,
  editServiceData,
  color
}) => {
  const [expandedLayers, setExpandedLayers] = useState({});
  const [transformedParent, setTransformedParent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const locationQuery = useLocation();
  const searchParams = new URLSearchParams(locationQuery.search);
  const v2PlannerId = searchParams.get("v2PlannerId");
  const plannerId = searchParams.get("id");
  const user = JSON.parse(localStorage.getItem("user"));
  const clickedPolygonParent = useSelector(
    (state) => state.planningDashboard.clickedPolygonParent
  );
  const [createRunPlannerService, { isLoading, isError }] =
    useCreateRunPlannerServiceMutation();
  const [updateRunPlannerService, { isLoading: isLoadingUpdateService, isError: isErrorUpdateService }] =
    useUpdateRunPlannerServiceMutation();
  const [addCoreService] =
  useAddCoreServiceMutation();

  useEffect(() => {
    const initialExpandedState = selectedLayers.reduce(
      (acc, layer) => ({ ...acc, [layer]: false }),
      {}
    );
    const initialSelectedState = selectedLayers.reduce(
      (acc, layerName) => {
        const layerDetails = planner.find((layer) => layer.coreLayerName === layerName);
        const selectAllParents = () => {
          // Check if layerDetails exist and initialize with default parents if needed
          const defaultParents = layerDetails
            ? layerDetails?.polygons?.reduce((acc, curr) => {
                let [parentId] = curr.pathId.split("-");
                let parentIdIndex = acc.findIndex(x => x.parentId === parentId)
                if(parentIdIndex > -1) {
                  acc[parentIdIndex] = {
                    ...acc[parentIdIndex],
                    polygons: [...acc[parentIdIndex].polygons, curr]
                  }
                } else {
                  acc.push({
                    parentId,
                    polygons: [curr]
                  })
                }
                return acc
              }, [])
            : [];
            return { ...acc, [layerName]: defaultParents };
        }
        if(isEditMode) {
          const runPlannerLayerId = editServiceData?.layers?.find(layer=>layer.v2LayerId ===layerDetails.v2LayerId)?.runPlannerLayerId
          if(Array.isArray(selectedParents[layerName]) && selectedParents[layerName].length > 0)
            return { ...acc, [layerName]: selectedParents[layerName] }
          else if(!runPlannerLayerId) 
            return selectAllParents();
          else
            return { ...acc, [layerName]: [] }
        } else return selectAllParents();
      },
      {}
    );
    setExpandedLayers(initialExpandedState);
    setSelectedParents(initialSelectedState);
  }, [selectedLayers, selectedParentsFlag]);

  const toggleExpand = (layer) => {
    setExpandedLayers((prev) => ({ ...prev, [layer]: !prev[layer] }));
  };

  const toggleParent = (parent, layer) => {
    let temp = {
      ...selectedParents,
      [layer]: selectedParents[layer].some((p) => p.parentId === parent.parentId)
        ? selectedParents[layer].filter((p) => p.parentId !== parent.parentId)
        : [...selectedParents[layer], parent],
    }
    setSelectedParents(temp);
  };

  useEffect(() => {
    if (!clickedPolygonParent?.pathId || !selectedLayers.includes(clickedPolygonParent.layerType)) {
      return;
    }
  
    const clickedLayerType = clickedPolygonParent.layerType;
    const clickedParentId = clickedPolygonParent.pathId.split("-")[0];
  
    const matchingLayer = transformedParent?.find(
      (layer) => layer.coreLayerName === clickedLayerType
    );
  
    if (!matchingLayer) return;
  
    const matchedParent = matchingLayer.parents?.find(
      (parent) => parent.parentId === clickedParentId
    );
  
    if (matchedParent) {
      const updatedParents = selectedParents[clickedLayerType]?.some(
        (p) => p.parentId === clickedParentId
      )
        ? selectedParents[clickedLayerType].filter((p) => p.parentId !== clickedParentId)
        : [...(selectedParents[clickedLayerType] || []), matchedParent];

      let temp = {
        ...selectedParents,
        [clickedLayerType]: updatedParents,
      };

      setSelectedParents(temp);
    }
  }, [clickedPolygonParent, selectedLayers, transformedParent]);
  

  useEffect(() => {
    if (selectedParents) {
      dispatch(setSelectedPolygonIds(selectedParents));
    }
  }, [selectedParents]);

  useEffect(() => {
    if (selectedLayers) {
      const transformedData = planner.map((layer) => {
        const parents = {};
        let totalMeasuredQty = 0;
        layer.polygons.forEach((polygon) => {
          totalMeasuredQty += polygon.measuredQty;
          const [parentId] = polygon.pathId.split("-");
          if (!parents[parentId]) {
            parents[parentId] = {
              parentId,
              polygons: [],
            };
          }
          parents[parentId].totalMeasuredQty = totalMeasuredQty.toFixed(2);
          parents[parentId].polygons.push(polygon);
        });

        return {
          coreLayerName: layer.coreLayerName,
          v2LayerId: layer.v2LayerId,
          parents: Object.values(parents),
        };
      });
      setTransformedParent(transformedData);
    }
  }, [selectedLayers]);

  const handleAddServiceLayer = async () => {
    setLoading(true);
    setError(null);
    try {
      // Prepare layers data
      const layers = selectedLayers
        .map((layerName) => {
          const layerDetails = planner.find(
            (layer) => layer.coreLayerName === layerName
          );
          if (!layerDetails) return null;
          const runPlannerLayerId = editServiceData?.layers?.find(layer=>layer.v2LayerId ===layerDetails.v2LayerId)?.runPlannerLayerId

          const polygons = selectedParents[layerName] || [];
          const filteredPolygons = polygons.flatMap(
            ({ polygons: parentPolygons }) => parentPolygons || []
          );
          return filteredPolygons.length > 0
            ? {
                v2LayerId: layerDetails.v2LayerId,
                ...(isEditMode && {runPlannerLayerId}),
                ...(isEditMode && {v2CoreLayerName: layerDetails.coreLayerName}),
                polygons: filteredPolygons.map(({ v2PolygonId }) => ({
                  v2PolygonId,
                })),
              }
            : null;
        })
        .filter((layer) => layer !== null);

      let coreServiceIdToUse = coreServiceId;

      if (selectedIsAddNewService) {
        try {
          const addServiceResponse = await addCoreService({
            serviceName: selectedNewServiceName,
            orgName: user?.organization,
          });

          if (addServiceResponse?.data) {
            coreServiceIdToUse = addServiceResponse.data.data;
          } else {
            throw new Error("Failed to add new core service.");
          }
        } catch (error) {
          toast.error("something went wrong", {
            autoClose: 2000,
            hideProgressBar: true,
          });
        }
      }

      // Construct the request body
      let requestBody = {
        orgName: user?.organization,
      }
      
      if(isEditMode) {
        requestBody.content = {
          v2RunPlannerId: Number(plannerId),
          coreServiceId: coreServiceIdToUse,
          completeBeforeServiceId: selectedCompleteBefore || null,
          v2RunPlannerServiceId: editServiceData?.v2RunPlannerServiceId,
          coreServiceName: editServiceData?.coreServiceName,
          color: color,
          layers,
          equipments: selectedEquipment || [],
        };
        const updateServiceResponse = await updateRunPlannerService(requestBody).unwrap();
        if (updateServiceResponse?.data) {
          handleClose(); // Close the popup after success
          dispatch(setSelectedPolygonIds({}));
        } else {
          throw new Error("Failed to update run planner service.");
        }
      } else {
        requestBody.content = {
          v2RunPlannerId: Number(plannerId),
          coreServiceId: coreServiceIdToUse,
          completeBeforeServiceId: selectedCompleteBefore || null,
          color,
          layers,
          equipments: selectedEquipment || [],
        };
        // Make the API call to create the run planner service
        const createServiceResponse = await createRunPlannerService(requestBody).unwrap();
        if (createServiceResponse?.data) {
          handleClose(); // Close the popup after success
          dispatch(setSelectedPolygonIds({}));
        } else {
          throw new Error("Failed to create run planner service.");
        }
      }
    } catch (error) {
      toast.error("something went wrong", {
        autoClose: 2000,
        hideProgressBar: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <Typography fontWeight="600" variant="body2" style={styles.headerText}>
          Layers
        </Typography>
      </div>
      <Box className="layersWrapper">
        {transformedParent &&
          transformedParent.map((layer) => {
            if (!selectedLayers.includes(layer.coreLayerName)) return null;
            return (
              <Layer
                key={layer.coreLayerName}
                layerName={layer.coreLayerName}
                parents={layer.parents}
                expanded={expandedLayers[layer.coreLayerName]}
                onToggleExpand={() => toggleExpand(layer.coreLayerName)}
                selectedParents={selectedParents[layer.coreLayerName]}
                onToggleParent={toggleParent}
              />
            );
          })}
      </Box>
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "space-between",
          background: "white",
          width: "100%",
          bottom: 0
        }}
      >
        <Button size="small" variant="contained" onClick={handleBack}>
          Prev
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handleAddServiceLayer}
          disabled={loading || isLoading}
        >
          {(loading || isLoading) && <CircularProgress size={14} color="grey" sx={{marginRight: "5px"}}/>} {isEditMode ? "Update" : "Add"} Service Layer
        </Button>
      </Box>
    </div>
  );
};

const Layer = ({
  layerName,
  parents,
  expanded,
  onToggleExpand,
  selectedParents,
  onToggleParent,
}) => {
  const tealShade1 = getCustomColor("tealShade1");
  return (
    <div style={styles.layerContainer}>
      <button onClick={onToggleExpand} style={styles.layerHeader}>
        <span style={{ display: "flex", alignItems: "center", gap: "0.25rem" }}>
          {expanded ? <FaCaretDown size={18} /> : <FaCaretRight size={18} />}
          {layerName}
        </span>
        <Chip label={`${selectedParents.length}/${parents.length} selected`}
          size="small"
          sx={{
            backgroundColor: tealShade1.main,
            color: tealShade1.shade1,
            fontSize: "0.75rem"
          }}
        />
      </button>
      {expanded && (
        <div style={styles.layerContent}>
          {parents.map((parent) => (
            <label
              className="layerCheckbox"
              key={parent.parentId}
              style={styles.checkboxContainer}
            >
              <Stack alignItems={"center"} gap={1} flexDirection={"row"}>
                <Checkbox
                  size="small"
                  checked={selectedParents.some(
                    (p) => p.parentId === parent.parentId
                  )}
                  onChange={() => onToggleParent(parent, layerName)}
                  sx={{padding: '0px'}}
                />
                Area {parent.parentId}
              </Stack>
              <Typography variant="caption" component={"span"}>
                {parent.totalMeasuredQty} units
              </Typography>
            </label>
          ))}
        </div>
      )}
    </div>
  )
}

const styles = {
  container: {
    flex: 1,
    position: "relative",
  },
  header: {
    backgroundColor: "#E0FFC4",
    borderRadius: "5px",
    padding: "0.5rem",
    margin: "0.5rem 0"
  },
  headerText: {
    color: "#4EA403",
    marginLeft: "10px",
  },
  layerContainer: {
    margin: "2px 0",
  },
  layerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    color: "black",
    border: "1px solid #8DE83D",
    padding: "10px",
    width: "100%",
    textAlign: "left",
    cursor: "pointer",
    // borderRadius: '5px',
    backgroundColor: "white",
  },
  layerContent: {
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#fff",
  },
  checkboxContainer: {
    borderBottom: "1px solid #8DE83D",
    padding: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "5px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    margin: "20px 0",
  },
};

export default DetailSectionStep;
