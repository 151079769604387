import React, { useEffect, useState } from "react";
import {
  ControlPosition,
  Map,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import { useDispatch, useSelector } from "react-redux";
import { calculateBounds } from "../../utils/mapZoomCalculation";
import { setIsStreetView } from "../../slices/globalSlice";
import PlannerBaseLayer from "./Layers/PlannerBaseLayer";
import PlannerSelectedLayers from "./Layers/PlannerSelectedLayers";
import PlanningMapHoveredPolygonLayer from "./Layers/PlanningMapHoveredPolygonLayer";
import { setClickedPolygonParent } from "../../slices/planningDashboardSlice";
import PlanningHoveredPolygonPopup from "./Layers/PlanningHoveredPolygonPopup";

const PlanningDashboardGoogleMaps = ({ mapData, coveredFilteredGeoJson }) => {
  const [baseFilteredGeoJson, setBaseFilteredGeoJson] = useState(null);
  const [mapHoveredGeoJson, setMapHoveredgeoJson] = useState(null);
  const [hoveredPolygonData, setHoveredPolygonData] = useState(null);
  const dispatch = useDispatch();

  const map = useMap();
  const mapsLibrary = useMapsLibrary("maps");
  const [viewport, setViewport] = useState({
    center: { lat: 40.7, lng: -74 },
    zoom: 12,
    width: 800,
    height: 400,
    bearing: 0,
    streetViewControl: true,
    fullscreenControl: false,
    fullscreenControlOptions: true,
  });

  // Update the viewport when mapData changes
  useEffect(() => {
    if (!mapData || !mapData.features || mapData.features.length === 0) {
      return;
    }

    const bounds = calculateBounds(mapData.features);
    setViewport((prevViewport) => ({
      ...prevViewport,
      center: {
        lat: (bounds[1] + bounds[3]) / 2,
        lng: (bounds[0] + bounds[2]) / 2,
      },
      zoom: 16,
    }));
  }, [mapData]);

  const handleClickedPolygon = (clickedData) => {
    if (clickedData?.object && clickedData?.object?.properties?.pathId) {
      dispatch(setClickedPolygonParent(clickedData?.object?.properties));
    }
  }

  const handleHoveredPolygon = (hoveredData, event) => {
    if (hoveredData?.object && hoveredData?.object?.properties?.pathId) {
      const selectedPolygonId = hoveredData?.object?.properties?.pathId;
      setHoveredPolygonData(hoveredData);

      const filteredFeatures = mapData?.features?.filter((feature) => {
        if (feature?.properties?.pathId) {
          return feature?.properties?.pathId !== undefined && feature?.properties?.pathId === selectedPolygonId;
        }
      });
      const filteredGeoJsonData = { ...mapData, features: filteredFeatures };
      if (filteredGeoJsonData) {
        setMapHoveredgeoJson(filteredGeoJsonData);
      } else {
        setMapHoveredgeoJson(null);
        setHoveredPolygonData(null);
      }
    } else {
      setMapHoveredgeoJson(null);
      setHoveredPolygonData(null);
    }
  };

  useEffect(() => {
    if (mapsLibrary && map) {
      setViewport((prev) => ({
        ...prev,
        mapTypeControlOptions: {
          style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
          position: 0,
        },
      }));
      const panorama = map?.getStreetView();
      panorama.addListener("visible_changed", () => {
        dispatch(setIsStreetView(panorama.getVisible()));
      });
    }
  }, [mapsLibrary, map]);

  return (
    <div style={{ height: "92vh", width: "100%" }}>
      <Map
        {...viewport}
        zoom={viewport?.zoom}
        defaultZoom={viewport?.zoom}
        center={viewport?.center}
        defaultCenter={viewport?.center}
        onCameraChanged={(v) => setViewport(v)}
        gestureHandling={"greedy"}
        draggableCursor="default" // Change this to any cursor style you want when the map is idle
        draggingCursor="move"
        tiltInteractionEnabled={true}
        defaultTilt={0}
        // disableDefaultUI= {true}
        mapId={"6f98ed508430fd59"}
        // mapId={"e29610dc72ad5f45"}
        // mapId={"24ece105f1731113"}
        defaultBounds={{
          south: 40.5,
          west: -74.2,
          north: 40.9,
          east: -73.8,
        }}
        mapTypeControlOptions={{
          style: mapsLibrary?.MapTypeControlStyle?.HORIZONTAL_BAR,
          position: ControlPosition.TOP_CENTER,
        }}
        stylers={true}
      >
        <PlannerBaseLayer
          layerData={mapData}
          coveredFilteredGeoJson={coveredFilteredGeoJson}
          handleHoveredPolygon={handleHoveredPolygon}
          handleClickedPolygon={handleClickedPolygon}
          //   isSummary={isSummary}
        />
        <PlanningMapHoveredPolygonLayer hoveredGeoJson={mapHoveredGeoJson} />
        <PlanningHoveredPolygonPopup hoveredPolygonData={hoveredPolygonData} setHoveredPolygonData={setHoveredPolygonData}/>
        <PlannerSelectedLayers
          layerData={coveredFilteredGeoJson}
          dimLayers={false}
          //   isSummary={isSummary}
        />
      </Map>
    </div>
  );
};

export default PlanningDashboardGoogleMaps;
