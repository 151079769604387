import {
  Box,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { PiWrenchBold } from "react-icons/pi";
import { StyledTableCell } from "../PlanningDashboardSidebarSummary";
import { getCustomColor } from "../../../utils/theme";
import {
  useDeleteCrewMemberMutation,
  useUpdateCrewMemberNameMutation,
} from "../../../services/OpenApi";
import AddCrewMemberModal from "../../../Modals/AddCrewMemberModal/AddCrewMemberModal";
import { IoMdClose } from "react-icons/io";
import { IoAlertCircleOutline } from "react-icons/io5";
import ConfirmationModal from "../../../Modals/ConfirmationModal/ConfirmationModal";

const CrewMemberTable = ({ crewMembersList, isGetApiLoading }) => {
  const blackShade1 = getCustomColor("blackShade1");
  const greenShade1 = getCustomColor("greenShade1");
  const [crewMembers, setCrewMembers] = useState([]);

  const [isAddEditCrewMemberModalOpen, setIsAddEditCrewMemberModalOpen] =
    useState(false);
  const [deleteCrewMember, { isLoading: isDeleting, error: deleteError }] =
    useDeleteCrewMemberMutation();
  const [editingCrewMember, setEditingCrewMember] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleAddCrewMember = (newCrewMember) => {
    setCrewMembers([...crewMembers, newCrewMember]);
  };

  React.useEffect(() => {
    if (crewMembersList) {
      setCrewMembers(Array.isArray(crewMembersList) ? crewMembersList : []);
    }
  }, [crewMembersList]);

  const handleEditCrewMember = (member) => {
    setEditingCrewMember(member);
    setIsAddEditCrewMemberModalOpen(true);
  };

  const openDeleteConfirmation = (id) => {
    setMemberToDelete(id);
    setIsConfirmationModalOpen(true);
  };

  const handleDeleteCrewMember = async () => {
    try {
      await deleteCrewMember({
        runPlannerCrewMemberId: memberToDelete,
        organisationName: user?.organization,
      }).unwrap();

      setCrewMembers(
        crewMembers.filter((member) => member.id !== memberToDelete)
      );
      setIsConfirmationModalOpen(false);
      setMemberToDelete(null);
    } catch (error) {
      console.error("Error deleting crew member:", error);
    }
  };

  return (
    <div className="crewMemberTableSection">
      <div className="sectionHeader">
        <div className="sectionHeaderLeft">
          <PiWrenchBold fontSize={26} />
          <Typography
            fontWeight="600"
            sx={{ color: blackShade1.shade1, fontSize: "16px" }}
            component="span"
            variant="h6"
          >
            Crew Member Details
          </Typography>
        </div>
        <button
          className="addButton"
          onClick={() => setIsAddEditCrewMemberModalOpen(true)}
        >
          Add Crew Member
        </button>
      </div>
      <TableContainer
        className="crewTableContainer"
        component={Paper}
        sx={{ boxShadow: "none", maxHeight: "19vh" }}
      >
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead sx={{ backgroundColor: "#8FE445", height: "10px" }}>
            <TableRow>
              <StyledTableCell style={{ paddingLeft: "20px" }}>
                Name
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  maxWidth: 80,
                  whiteSpace: "nowrap",
                  backgroundColor: "#8FE445",
                  color: "white",
                  fontWeight: 600,
                  fontSize: "16px",
                  padding: "0px 6px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "10px",
                  }}
                >
                  Actions
                </Box>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isGetApiLoading ? (
              Array.from({ length: 4 }).map((_, index) => (
                <TableRow key={index}>
                  <StyledTableCell>
                    <Skeleton
                      sx={{ bgcolor: greenShade1?.skeleton }}
                      variant="text"
                      width={150}
                      height={25}
                    />
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <Skeleton
                      sx={{ bgcolor: greenShade1?.skeleton }}
                      variant="text"
                      width={250}
                      height={25}
                    />
                  </StyledTableCell>
                </TableRow>
              ))
            ) : crewMembers.length === 0 ? (
              <TableRow>
                <StyledTableCell colSpan={2}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      textAlign: "center",
                      fontSize: "13px",
                      color: "rgb(95, 97, 99)",
                    }}
                  >
                    No crew member added
                  </Typography>
                </StyledTableCell>
              </TableRow>
            ) : (
              crewMembers.map((member) => (
                <TableRow key={member.id}>
                  <StyledTableCell
                    sx={{
                      maxWidth: 200,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    style={{ paddingLeft: "20px" }}
                  >
                    {member.crewMemberName}
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      paddingRight: "15px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <button
                        onClick={() => {
                          handleEditCrewMember(member);
                        }}
                        className="buttonStyle"
                      >
                        <img
                          src="/images/Icons/PencilSimple.png"
                          alt="Edit"
                          style={{ width: "17px", height: "17px" }}
                        />
                      </button>
                      &nbsp;&nbsp;&nbsp;
                      <button
                        onClick={() => openDeleteConfirmation(member.id)}
                        className="buttonStyle"
                      >
                        <img
                          src="/images/Icons/Trash.png"
                          alt="Delete"
                          style={{ width: "17px", height: "17px" }}
                        />
                      </button>
                    </Box>
                  </StyledTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <AddCrewMemberModal
        isOpen={isAddEditCrewMemberModalOpen}
        editData={editingCrewMember}
        handleClose={() => setIsAddEditCrewMemberModalOpen(false)}
        handleAddCrewMember={handleAddCrewMember}
        setCrewMembers={setCrewMembers}
        setEditingCrewMember={setEditingCrewMember}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        body="Do you want to delete this crew member?"
        handleOk={handleDeleteCrewMember}
        isLoading={isDeleting}
      />
    </div>
  );
};

export default CrewMemberTable;
