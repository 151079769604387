import {
    Box,
    Drawer
  } from "@mui/material";
  import React from "react";
  import { useSelector } from "react-redux";
  import useViewportWidth from "../../Hooks/useViewportWidth";
  import "../GISDashboard/GISDashboardSidebar.css"
  import SidebarDetail from "../GISDashboard/SidebarDetail";
import { PlanningDashboardSidebarSummary } from "./PlanningDashboardSidebarSummary";
import PlanningDashboardPlannedData from "./Components/PlanningDashboardPlannedData";
  
  const DashboardSidebar = ({ isPlannerDrawerOpen}) => {
    const isSummary = useSelector((state) => state.gisDashboard.isSummary);
    const initialCrewData = useSelector(
      (state) => state.global.initialCrewMembers
    );
    const width = useViewportWidth();
    return (
      <Drawer
        anchor="left"
        open={isPlannerDrawerOpen}
        hideBackdrop
        variant="persistent"
        sx={{
          "& .MuiDrawer-paper": {
            width: width < 768 ? "100vw" : "35vw",
            minWidth: "32rem",
            top: "8vh",
            zIndex: 1,
          },
        }}
      >
          <PlanningDashboardSidebarSummary />
        {/* <Box className="sidebarDrawer">
          {initialCrewData && <PlanningDashboardPlannedData />}
          
        </Box>
         */}
      </Drawer>
    );
  };
  
  export default DashboardSidebar;
  