import { configureStore } from "@reduxjs/toolkit";
import globalReducer from "../slices/globalSlice";
import workPlanSlice from "../slices/workPlanSlice";
import animationSlice from "../slices/animationSlice";
import createCrewSlice from "../slices/createCrewSlice";
import { authApi } from "../services/Auth";
import { setupListeners } from "@reduxjs/toolkit/query";
import { PropertiesApi } from "../services/Properties";
import { openApi } from "../services/OpenApi";
import stepperFormSlice from "../slices/stepperFormSlice";
import drawingSlice from "../slices/drawingSlice";
import GISDashboardSlice from "../slices/gisDashboardSlice";
import planningDashboardSlice from "../slices/planningDashboardSlice";

export const store = configureStore({
  reducer: {
    global: globalReducer,
    workPlan: workPlanSlice,
    animation: animationSlice,
    createCrew: createCrewSlice,
    stepperForm: stepperFormSlice,
    drawing: drawingSlice,
    gisDashboard: GISDashboardSlice,
    planningDashboard: planningDashboardSlice,
    [authApi.reducerPath]: authApi.reducer,
    [openApi.reducerPath]: openApi.reducer,
    [PropertiesApi.reducerPath]: PropertiesApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authApi.middleware)
      .concat(openApi.middleware)
      .concat(PropertiesApi.middleware)
});

setupListeners(store.dispatch);
