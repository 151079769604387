import "./App.css";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppRoutes from "./Routes/AppRoutes";
import {
  setIsLogin,
  setToken,
  setUser,
} from "./slices/globalSlice";
import { useTokenRefresh } from "./utils/refreshTokenUtils";

function App() {
  const isToken = localStorage.getItem("accessToken");
  const user = localStorage.getItem("user");
  const token = useSelector((state) => state.global.token);
  const dispatch = useDispatch();
  useTokenRefresh(token);

  useEffect(() => {
    if (user) {
      dispatch(setUser(JSON.parse(user)));
    }
  }, []);

  if (isToken && !token) {
    dispatch(setToken(isToken));
    dispatch(setIsLogin(true));
  }
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
