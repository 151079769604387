import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DeckGlOverlay } from "../../../utils/deckgl-overlay";
import { GeoJsonLayer } from "@deck.gl/layers";
import { getServiceColorRgb } from "../../../utils/common";
import useViewportWidth from "../../../Hooks/useViewportWidth";

function getDeckGlLayers(
  data,
  filteredGeoJson,
  hoveredGeoJson,
  handleClickedPolygon,
  width,
  isCrewMemberSelected
) {
  if (!data) return [];

  return [
    new GeoJsonLayer({
      id: "geojson",
      data: data,
      stroked: true,
      filled: true,
      getLineWidth:
        isCrewMemberSelected ||
        (hoveredGeoJson && Object.keys(hoveredGeoJson).length > 0)
          ? 0.1
          : 0.2,
      opacity:
        isCrewMemberSelected ||
        (hoveredGeoJson && Object.keys(hoveredGeoJson).length > 0)
          ? 0.02
          : 1,
      getFillColor: (f) => {
        return f?.properties?.serviceType == "obstacle"
          ? [0, 0, 0, 0]
          : getServiceColorRgb(f?.properties?.serviceType);
      },
      getLineColor: (f) => getServiceColorRgb(f?.properties?.serviceType),
      lineWidthMinPixels: 2,
      parameters: {
        depthTest: false,
      },
      pickable: width < 800 ? false : true,
      onClick: (info) => handleClickedPolygon(info),
    }),
  ];
}

const InitialServiceLayers = ({
  hoveredGeoJson,
  filteredGeoJson,
  handleClickedPolygon,
}) => {
  const [isVisible, setIsVisible] = useState(true);
  const [isCrewMemberSelected, setIsCrewMemberSelected] = useState(true);
  const width = useViewportWidth();
  const {
    global: { geoJsonFilteredData, showObstacles },
  } = useSelector((state) => state);
  const selectedCrewMembersId = useSelector(
    (state) => state.global.selectedCrewMembersId
  );

  useEffect(() => {
    if (selectedCrewMembersId) {
      let isTrue = false;
      Object.keys(selectedCrewMembersId).map((key) => {
        if (selectedCrewMembersId[key]) {
          isTrue = true;
        }
      });
      setIsCrewMemberSelected(isTrue);
    }
  }, [selectedCrewMembersId]);
  const filteredData = showObstacles
    ? geoJsonFilteredData
    : geoJsonFilteredData?.features?.filter(
        (feature) => feature?.properties?.serviceType !== "obstacle"
      );

  // this visible functionality is added due to webgl context lost issue
  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsVisible(!document.hidden);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);
  return (
    <DeckGlOverlay
      layers={getDeckGlLayers(
        filteredData,
        filteredGeoJson,
        hoveredGeoJson,
        handleClickedPolygon,
        width,
        isCrewMemberSelected
      )}
      pickable={width < 800 ? false : true}
      typedArrayManagerProps={
        width < 800 ? { overAlloc: 1, poolSize: 0 } : null
      }
      isVisible={isVisible}
    />
  );
};

export default InitialServiceLayers;
