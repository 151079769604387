import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";

const initialState = {
  selectedLocationValue: null,
  baseLayersGeojson: null,
  isSummary: true,
  summaryData: null,
  selectedDay: null,
  selectedServiceForDetails: null,
  coveredLayersGeojson: null,
  coveredLayersDateWiseGeojson: null,
  weekCalenderValue: dayjs(new Date()),
  selectedProperty: "mayport",
  weekSummaryFiles: {},
  selectedGISService: {},
  selectedGISServicePathOfMotion: {},
  selectedDevices:{},
  selectedGISServiceDate: {},
  selectedWeekSummary: null,
  selectedWeek: null,
  devicesColor : null,
  pathOfMotionData: null,
  pathOfMotionEntryPointsData: null,
  detailedData: null,
  beaconsListData : null,
  isPathOfMotion: false,
  allWeekDaysCombinedGeoJson: {
    type: "FeatureCollection",
    features: [],
  },
};

const GISDashboardSlice = createSlice({
  name: "gisDashboard",
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedLocationValue: (state, action) => {
      return {
        ...state,
        selectedLocationValue: action.payload,
      };
    },
    setBaseLayersGeojson: (state, action) => {
      return {
        ...state,
        baseLayersGeojson: action.payload,
      };
    },
    setIsSummary: (state, action) => {
      return {
        ...state,
        isSummary: action.payload,
      };
    },
    setSummaryData: (state, action) => {
      return {
        ...state,
        summaryData: action.payload,
      };
    },
    setSelectedDay: (state, action) => {
      return {
        ...state,
        selectedDay: action.payload,
      };
    },
    setSelectedServiceForDetails: (state, action) => {
      return {
        ...state,
        selectedServiceForDetails: action.payload,
      };
    },
    setCoveredLayersGeojson: (state, action) => {
      return {
        ...state,
        coveredLayersGeojson: action.payload,
      };
    },
    setCoveredLayersDateWiseGeojson: (state, action) => {
      return {
        ...state,
        coveredLayersDateWiseGeojson: action.payload,
      };
    },
    setWeekCalenderValue: (state, action) => {
      return {
        ...state,
        weekCalenderValue: action.payload,
      };
    },
    setSelectedProperty: (state, action) => {
      return {
        ...state,
        selectedProperty: action.payload,
      };
    },
    setIsPathOfMotion: (state, action) => {
      return {
        ...state,
        isPathOfMotion: action.payload,
      };
    },
    setWeekSummaryFiles: (state, action) => {
      const { serviceName, results } = action.payload;
      state.weekSummaryFiles[serviceName] = results;
    },
    setSelectedGISService: (state, action) => {
      if (typeof action.payload === "string") {
        const serviceType = action.payload;
        state.selectedGISService[serviceType] =
          !state.selectedGISService[serviceType];
      } else if (typeof action.payload === "object") {
        state.selectedGISService = action.payload;
      }
    },
    setSelectedGISServicePathOfMotion: (state, action) => {
      if (typeof action.payload === "string") {
        const serviceType = action.payload;
        state.selectedGISServicePathOfMotion[serviceType] =
          !state.selectedGISServicePathOfMotion[serviceType];
      } else if (typeof action.payload === "object") {
        state.selectedGISServicePathOfMotion = action.payload;
      }
    },
    setSelectedDevices: (state, action) => {
      if (typeof action.payload === "string") {
        const serviceType = action.payload;
        state.selectedDevices[serviceType] =
          !state.selectedDevices[serviceType];
      } else if (typeof action.payload === "object") {
        state.selectedDevices = action.payload;
      }
    },
    setSelectedGISServiceDate: (state, action) => {
      if (typeof action.payload === "string") {
        const serviceType = action.payload;
        state.selectedGISServiceDate[serviceType] =
          !state.selectedGISServiceDate[serviceType];
      } else if (typeof action.payload === "object") {
        state.selectedGISServiceDate = action.payload;
      }
    },
    setSelectedWeek: (state, action) => {
      return {
        ...state,
        selectedWeek: action.payload,
      };
    },
    setDevicesColor: (state, action) => {
      return {
        ...state,
        devicesColor: action.payload,
      };
    },
    setPathOfMotionData: (state, action) => {
      return {
        ...state,
        pathOfMotionData: action.payload,
      };
    },
    setPathOfMotionEntryPointsData: (state, action) => {
      return {
        ...state,
        pathOfMotionEntryPointsData: action.payload,
      };
    },
    setSelectedWeekSummary: (state, action) => {
      return {
        ...state,
        selectedWeekSummary: action.payload,
      };
    },
    setDetailedData: (state, action) => {
      return {
        ...state,
        detailedData: action.payload,
      };
    },
    setBeaconsListData: (state, action) => {
      return {
        ...state,
        beaconsListData: action.payload,
      };
    },
    setAllWeekDaysCombinedGeoJson: (state, action) => {
      state.allWeekDaysCombinedGeoJson.features.push(action.payload);
    },
    resetAllWeekDaysCombinedGeoJson: (state) => {
      state.allWeekDaysCombinedGeoJson = {
        type: "FeatureCollection",
        features: [],
      };
    },
  },
});

export const {
  reset,
  setSelectedLocationValue,
  setBaseLayersGeojson,
  setIsSummary,
  setSummaryData,
  setSelectedDay,
  setSelectedServiceForDetails,
  setCoveredLayersGeojson,
  setCoveredLayersDateWiseGeojson,
  setWeekCalenderValue,
  setSelectedProperty,
  setWeekSummaryFiles,
  setSelectedGISService,
  setSelectedGISServicePathOfMotion,
  setSelectedDevices,
  setSelectedGISServiceDate,
  setSelectedWeekSummary,
  setSelectedWeek,
  setDevicesColor,
  setPathOfMotionData,
  setDetailedData,
  setBeaconsListData,
  setIsPathOfMotion,
  setPathOfMotionEntryPointsData,
  setAllWeekDaysCombinedGeoJson,
  resetAllWeekDaysCombinedGeoJson,
} = GISDashboardSlice.actions;

export default GISDashboardSlice.reducer;
