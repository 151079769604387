import { Button } from '@mui/material';
import JSZip from 'jszip';
import Pbf from 'pbf';
import React, { useRef } from 'react';
import { toast } from "react-toastify";
import geobuf from 'geobuf';

const GeoBufJSONUploadButton = ({ onFileUpload, buttonText = 'Upload GeoBuf' }) => {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (onFileUpload && file) {
      try {
        const arrayBuffer = await file.arrayBuffer(); 
        const pbf = new Pbf(new Uint8Array(arrayBuffer));
        const geojson = geobuf.decode(pbf);
        onFileUpload(geojson);
      } catch (err) {
        toast.error("Not able to parse the uploaded file", {
          autoClose: 2000,
          hideProgressBar: true,
        });
      }
    }
    if (event.target.files.length > 0) {
      event.target.value = null;
    }
  };
  
  return (
    <div className="uploadButtonContainer">
      <input
        type="file"
        accept=" .geobuf"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <Button fullWidth size='small' variant='contained' onClick={handleButtonClick}>{buttonText}</Button>
    </div>
  );
};

export default GeoBufJSONUploadButton;
