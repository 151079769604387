import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useCreateRunPlannerMutation } from "../../services/OpenApi";
import { useLocation, useNavigate } from "react-router-dom";

const AddRunPlannerFileNameModal = ({ isOpen, onClose, fileData }) => {
  const [fileName, setFileName] = useState("");
  const [createRunPlanner, { isLoading }] = useCreateRunPlannerMutation();
  const navigate = useNavigate();

  const handleFileNameChange = (e) => {
    setFileName(e.target.value);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const handleFileSave = async () => {
    try {
      const response = await createRunPlanner({
        name: fileName,
        v2PlannerId: fileData?.v2PlannerId,
        propertyName: fileData?.site,
        organisationName: user?.organization,
      }).unwrap();

      if (response?.data) {
        navigate(
          `/addServices?id=${response?.data}&v2PlannerId=${fileData?.v2PlannerId}`
        );
        setFileName("");
        onClose();
      }
    } catch (error) {
      console.error("Failed to create run planner:", error);
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="xs">
        <DialogTitle>Add New Run Planner</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            size="small"
            label="Run Planner Name"
            fullWidth
            value={fileName}
            onChange={handleFileNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            onClick={handleFileSave}
            disabled={isLoading}
            variant="contained"
          >
            {isLoading && <CircularProgress size={18} thickness={5} />} Add
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddRunPlannerFileNameModal;
