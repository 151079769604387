import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APIProvider } from "@vis.gl/react-google-maps";
import GISDashboardGoogleMaps from "../GISDashboard/GISDashboardGoogleMaps";
import { Box, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import DashboardSidebar from "./PlanningDashboardSidebar";
import { fetchS3Data } from "../../Modals/LocationGroupModal/LocationGroupModal";
import PlanningDashboardGoogleMaps from "./PlanningDashboardGoogleMaps";
import TimelineDrawer from "../MapScreen/TimelineDrawer";
import { TbTimeline } from "react-icons/tb";
import { AiOutlineControl } from "react-icons/ai";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import useViewportWidth from "../../Hooks/useViewportWidth";
import lookUpData from "../../files/plannerLookup.json";
import { setInitialCrewMembers, setLookUpData } from "../../slices/globalSlice";
import {
  assignCrewMemberColors,
  calculateServicesTotal,
  calculateTotalAssignmentsForCrewMember,
} from "../../utils/common";

const DashboardMapScreen = () => {
  const mapRef = useRef(null);
  const drawerRef = useRef();
  const width = useViewportWidth();
  const dispatch = useDispatch();
  const [drawerHeight, setDrawerHeight] = useState(0);
  const [mapGeoJson, setMapGeoJson] = useState(null);
  const [coveredFilteredGeoJson, setCoveredFilteredGeoJson] = useState(null);
  const isStreetView = useSelector((state) => state.global.isStreetView);
  const [isPlannerDrawerOpen, setIsPlannerDrawerOpen] = useState(true);
  const [isMapControlsDrawerOpen, setIsMapControlsDrawerOpen] = useState(false);
  const [isTimelineDrawerOpen, setIsTimelineDrawerOpen] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const selectedPolygonIds = useSelector(
    (state) => state.global.selectedPolygonIds
  );
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if(lookUpData) {

      const fileData = lookUpData.lookUpOutput || lookUpData;
      const final = calculateTotalAssignmentsForCrewMember(fileData);
      const withServices = calculateServicesTotal(final);
      const crewColors = assignCrewMemberColors(withServices);
      dispatch(setLookUpData(crewColors));
      dispatch(setInitialCrewMembers(withServices.crewMemberDayWise));
    }
  },[lookUpData]);

  useEffect(() => {
    if (selectedPolygonIds && mapGeoJson) {
      const selectedIds = Object.keys(selectedPolygonIds).flatMap(
        (serviceName) =>
          selectedPolygonIds[serviceName]?.flatMap((parent) =>
            parent?.polygons?.map((polygon) => polygon?.pathId)
          )
      );

      // Filter mapGeoJson features based on the pathId
      const filteredFeatures = mapGeoJson?.features?.filter((feature) =>
        selectedIds.includes(feature.properties.pathId)
      );
      // Update the state or perform actions with filtered features
      setCoveredFilteredGeoJson({
        type: "FeatureCollection",
        features: filteredFeatures,
      });
    }
  }, [selectedPolygonIds, mapGeoJson]);

  const togglePlannerDrawer = () => {
    setIsPlannerDrawerOpen(!isPlannerDrawerOpen);
  };

  const fetchGeoBufData = async () => {
    const response = await fetchS3Data(
      "https://dev-topg-planner-gis.s3.ap-southeast-1.amazonaws.com/KMLResponse/jobplanningtest/BDTech_169_1/BDTechnologies_squared.geojson"
    );
    setMapGeoJson(response);
    // const arrayBuffer = await response.arrayBuffer();
    //     const pbf = new Pbf(new Uint8Array(arrayBuffer));
    //     const geojson = geobuf.decode(pbf);
  };

  useEffect(() => {
    fetchGeoBufData();
  }, []);

  useEffect(() => {
    // Ensure the drawerRef is currently pointing to an element
    const drawerElement = drawerRef.current;
    if (!drawerElement) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setDrawerHeight(entry.contentRect.height);
      }
    });

    resizeObserver.observe(drawerElement);

    // Cleanup function to safely unobserve
    return () => {
      if (drawerElement) {
        const handleTabChange = (event, newValue) => {
          setTabValue(newValue);
        };
        resizeObserver.unobserve(drawerElement);
      }
    };
  }, [isTimelineDrawerOpen]);

  const handleViewTimelineButton = () => {
    setIsTimelineDrawerOpen(!isTimelineDrawerOpen);
  };

  const handleMapControlsDrawer = () => {
    setIsMapControlsDrawerOpen(!isMapControlsDrawerOpen);
  };

  const toggleMapControlsDrawer = () => {
    setIsMapControlsDrawerOpen(!isMapControlsDrawerOpen);
  };

  return (
    <div className="mapPanelContainer">
      <div className="mapboxMapContainer">
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}>
          <PlanningDashboardGoogleMaps
            mapData={mapGeoJson}
            mapRef={mapRef}
            coveredFilteredGeoJson={coveredFilteredGeoJson}
          />
        </APIProvider>
      </div>
      <Box
        className="sidebarButton"
        sx={{ top: "50%", left: isPlannerDrawerOpen ? "35vw" : "0vw" }}
        onClick={togglePlannerDrawer}
      >
        <IoMdArrowDropright fontSize={26} />
      </Box>
      <DashboardSidebar
        togglePlannerDrawer={togglePlannerDrawer}
        isPlannerDrawerOpen={isPlannerDrawerOpen}
      />
      {/* {!isStreetView && (
        <>
          <button
            className="viewTimelineButton"
            onClick={handleViewTimelineButton}
          >
            <TbTimeline size={20} /> <Typography>View Timeline </Typography>{" "}
            <TiArrowSortedUp />
          </button>
          <button className="viewMapControls" onClick={handleMapControlsDrawer}>
            <AiOutlineControl size={20} /> <Typography>Map Controls</Typography>{" "}
            <TiArrowSortedDown />
          </button>
        </>
      )} */}
      {isTimelineDrawerOpen && (
        <div
          className={`changeTimelineSection ${
            isTimelineDrawerOpen ? "active" : ""
          }`}
          style={{
            bottom: `${drawerHeight}px`, // Adjust based on drawer's height
            left: isPlannerDrawerOpen && width > 768 ? "27vw" : 0,
          }}
        >
          {width > 500 ? (
            <div className="tabChange">
              <Tabs
                value={tabValue}
                fontSize={10}
                onChange={handleTabChange}
                centered
              >
                <Tab fontSize={10} label="Crew Member" />
                <Tab label="Crew Wise" />
                <Tab label="Service Wise" />
              </Tabs>
            </div>
          ) : (
            <Select
              value={tabValue}
              className="tabSelect"
              onChange={(e) => setTabValue(e.target.value)}
              fullWidth
            >
              <MenuItem selected value={0}>
                Crew Member
              </MenuItem>
              <MenuItem value={1}>Crew Wise</MenuItem>
              <MenuItem value={2}>Service Wise</MenuItem>
            </Select>
          )}
          {/* <CrewMemberDaySelect data={lookUpData?.crewMemberDayWise} /> */}
        </div>
      )}
      {/* {isTimelineDrawerOpen && (
        <div>
          <button
            className={`closeTimelineButton`}
            style={{
              bottom: `${drawerHeight}px`, // Adjust based on drawer's height
            }}
            onClick={() => setIsTimelineDrawerOpen(!isTimelineDrawerOpen)}
          >
            <IoMdArrowDropdown />
          </button>
        </div>
      )} */}
      {/* <TimelineDrawer
        isPlannerDrawerOpen={isPlannerDrawerOpen}
        isTimelineDrawerOpen={isTimelineDrawerOpen}
        // setHoveredPolygon={setHoveredPolygon}
        drawerRef={drawerRef}
        tabValue={tabValue}
      /> */}
    </div>
  );
};

export default DashboardMapScreen;
