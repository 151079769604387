import {
    Box,
    Checkbox,
    Drawer,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    styled,
    tableCellClasses,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import {
    capitalizeFirstLetter,
    convertTime,
    formatDistance,
    getLengthUnit,
    truncateString,
  } from "../../../utils/common";
  import {
    setCrewMemberDayWiseFilteredData,
    setGeoJsonFilteredData,
    setSelectedCrewMembersId,
    setSelectedServices,
  } from "../../../slices/globalSlice";
  import SelectPlanModal from "../../../Modals/SelectPlanModal/SelectPlanModal";
  import useViewportWidth from "../../../Hooks/useViewportWidth";
  import { getCustomColor } from "../../../utils/theme";
  import ColorPickerModal from "../../../Modals/ColorPickerModal/ColorPickerModal";
  
  export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      fontWeight: 600,
      fontSize: "13px",
    },
    whiteSpace: "nowrap",
  }));
  
  const PlanningDashboardPlannedData = ({
    isSolver = false,
  }) => {
    const blackShade1 = getCustomColor("blackShade1");
    const width = useViewportWidth();
    const initialCrewData = useSelector(
      (state) => state.global.initialCrewMembers
    );
    const [isSelectPlanOpen, setIsSelectPlanOpen] = useState(false);
    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const [crewMemberColorData, setCrewMemberColorData] = useState(false);
    const isPremium = useSelector((state) => state.global.isPremium);
    const mapData = useSelector((state) => state.global.mapData);
    const [selectedCrewMembers, setSelectedCrewMembers] = useState(
      Object.keys(initialCrewData).reduce((acc, crewMemberId) => {
        acc[crewMemberId] = false;
        return acc;
      }, {})
    );
  
    useEffect(() => {
      if (mapData) {
        const uniqueServices = {};
        mapData.features.forEach((feature) => {
          const serviceType = feature.properties.serviceType;
          if (!uniqueServices[serviceType]) {
            uniqueServices[serviceType] = true;
          }
        });
        dispatch(setSelectedServices(uniqueServices));
      }
    }, [mapData]);
    const dispatch = useDispatch();
    const selectedDay = useSelector((state) => state.global.selectedDay);
    const lookUp = useSelector((state) => state.global.lookUpData);
    const selectedServices = useSelector(
      (state) => state.global.selectedServices
    );
  
    useEffect(() => {
      if (initialCrewData) {
        setSelectedCrewMembers(
          Object.keys(initialCrewData).reduce((acc, crewMemberId) => {
            acc[crewMemberId] = false;
            return acc;
          }, {})
        );
      }
    }, [initialCrewData, selectedDay]);
  
    useEffect(() => {
      if (mapData) {
        const filteredFeatures = mapData.features.filter(
          (feature) => selectedServices[feature.properties.serviceType]
        );
  
        // Construct a new GeoJSON object containing only the filtered features
        const newFilteredGeoJSON = {
          ...mapData,
          features: filteredFeatures,
        };
        dispatch(setGeoJsonFilteredData(newFilteredGeoJSON));
      }
    }, [selectedServices, mapData]);
  
    const getFilteredData = () => {
      // isSolver is used to render map on create plan screen, this is because we are rendering all of the crewmembers data on create plan screen
      if (isSolver) {
        return Object.keys(initialCrewData).reduce((acc, id) => {
          acc[id] = { ...initialCrewData[id] };
          // Filter day data based on the selected day
          if (selectedDay == "All") acc[id].day = acc[id].day;
          else {
            acc[id].day = acc[id].day.filter((day) => day.day === selectedDay);
          }
          return acc;
        }, {});
      } else {
        return Object.keys(initialCrewData)
          .filter((crewMemberId) => selectedCrewMembers[crewMemberId])
          .reduce((acc, id) => {
            acc[id] = { ...initialCrewData[id] };
            // Filter day data based on the selected day
            if (selectedDay == "All") acc[id].day = acc[id].day;
            else {
              acc[id].day = acc[id].day.filter((day) => day.day === selectedDay);
            }
            return acc;
          }, {});
      }
    };
  
    useEffect(() => {
      const filteredData = getFilteredData();
      dispatch(setCrewMemberDayWiseFilteredData(filteredData));
    }, [selectedCrewMembers, dispatch]);
  
    const handleCrewMemberCheckboxChange = (id, isChecked) => {
      setSelectedCrewMembers((prev) => ({ ...prev, [id]: isChecked }));
    };
    useEffect(() => {
      dispatch(setSelectedCrewMembersId(selectedCrewMembers));
    }, [selectedCrewMembers, dispatch]);
  
    const getCrewMemberColor = (crewMemberId) => {
      const crewData = lookUp?.crewMemberDayWise;
      if (crewData[crewMemberId]) {
        return crewData[crewMemberId].crewMemberColor;
      } else {
        return "Crew member not found";
      }
    };
  
    const handleServicesCheckboxChange = (serviceType) => {
      dispatch(setSelectedServices(serviceType));
    };
  
    const servicesArray = lookUp?.services ? Object.values(lookUp?.services) : [];
    const crewMembersArray = lookUp?.crewMembers
      ? Object.entries(lookUp?.crewMembers)
      : [];
  
    const handleCrewMemberColor = (crewMemberId, crewMemberColor) => {
      setIsColorPickerOpen(true);
      setCrewMemberColorData({
        crewMemberId: crewMemberId,
        crewMemberColor: crewMemberColor,
      });
    };
  
    const totalServiceWorkTime = servicesArray?.reduce(
      (total, service) => total + service?.workTime,
      0
    );
    const totalServiceTravelTime = servicesArray?.reduce(
      (total, service) => total + service?.travelTime,
      0
    );
  
    // Calculate total work time, travel time, and distance for crew members
    const totalCrewWorkTime = crewMembersArray?.reduce(
      (total, [, crewMember]) => total + crewMember?.totalAssignTimeOfWork,
      0
    );
    const totalCrewTravelTime = crewMembersArray?.reduce(
      (total, [, crewMember]) => total + crewMember?.totalAssignTravelTime,
      0
    );
    const totalCrewDistance = crewMembersArray?.reduce(
      (total, [, crewMember]) => total + crewMember?.totalAssignTravelDistance,
      0
    );
  
    return (
        <>
        <div className="sidebarDrawer">
          <div className="plannerDetailsContianer">
            {lookUp && (
              <>
                <div className="servicesContainer">
                  {/* <Typography className="subTitlePlanner">Service Details</Typography> */}
                  <Typography
                    component="span"
                    fontWeight="600"
                    marginLeft={1}
                    sx={{ color: blackShade1.main }}
                    variant="body1"
                  >
                    Service Details
                  </Typography>
                  <div>
                    <TableContainer component={Paper}>
                      <Table
                        // sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Service</StyledTableCell>
                            <StyledTableCell align="center">Work</StyledTableCell>
                            <StyledTableCell align="center">
                              Travel
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <Tooltip title="Measurments">Units</Tooltip>
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {servicesArray.map((service, index) => {
                            const {
                              serviceName,
                              workTime,
                              travelTime,
                              totalArea,
                            } = service;
                            const formattedServiceName = serviceName.replace(
                              "_",
                              " "
                            );
                            return (
                              <TableRow
                                key={workTime * index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <StyledTableCell component="th" scope="row">
                                  <div className="plannerNameContainer">
                                    <div className="checkboxContainer">
                                      <Checkbox
                                        size="small"
                                        checked={
                                          selectedServices[formattedServiceName]
                                            ? selectedServices[
                                                formattedServiceName
                                              ]
                                            : false
                                        }
                                        onChange={() =>
                                          handleServicesCheckboxChange(
                                            formattedServiceName
                                          )
                                        }
                                      />
                                    </div>
  
                                    <div className="serviceNameContainer">
                                      <Tooltip title={formattedServiceName}>
                                        <div>
                                          {truncateString(
                                            formattedServiceName,
                                            13
                                          )}
                                        </div>
                                      </Tooltip>
                                      <div
                                        className={`Plannercolor ${serviceName.replace(
                                          " ",
                                          "_"
                                        )}`}
                                      />
                                    </div>
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {convertTime(workTime?.toFixed(2))}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {convertTime(travelTime?.toFixed(2))}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {totalArea?.toFixed(0)}{" "}
                                  {getLengthUnit(formattedServiceName)}
                                </StyledTableCell>
                              </TableRow>
                            );
                          })}
                          <TableRow>
                            <StyledTableCell component="th" scope="row">
                              <Typography
                                fontWeight="500"
                                component="span"
                                variant="subtitle2"
                              >
                                Total ({" "}
                                {convertTime(
                                  (
                                    totalServiceWorkTime + totalServiceTravelTime
                                  ).toFixed(2)
                                )}{" "}
                                )
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {convertTime(totalServiceWorkTime?.toFixed(2))}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {convertTime(totalServiceTravelTime?.toFixed(2))}
                            </StyledTableCell>
                            <StyledTableCell align="center"></StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
                <div className="plannerCrewMembers">
                  <Typography
                    component="span"
                    fontWeight="600"
                    marginLeft={1}
                    sx={{ color: blackShade1.main }}
                    variant="body1"
                  >
                    Crew Member Details
                  </Typography>
                  <div className="tableContainer">
                    <TableContainer component={Paper}>
                      <Table size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="center">Work</StyledTableCell>
                            <StyledTableCell align="center">
                              Travel
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Distance
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {crewMembersArray
                            .reverse()
                            .map(([crewMemberId, crewMember], index) => {
                              const {
                                name,
                                title,
                                totalAssignTimeOfWork,
                                totalAssignTravelDistance,
                                totalAssignTravelTime,
                              } = crewMember;
                              const crewMemberColor =
                                getCrewMemberColor(crewMemberId);
                              return (
                                <TableRow
                                  key={totalAssignTimeOfWork * index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <StyledTableCell component="th" scope="row">
                                    <div className="plannerNameContainer">
                                      <div className="checkboxContainer">
                                        <Checkbox
                                          checked={
                                            selectedCrewMembers[crewMemberId]
                                          }
                                          onChange={(e) =>
                                            handleCrewMemberCheckboxChange(
                                              crewMemberId,
                                              e.target.checked
                                            )
                                          }
                                          size="small"
                                        />
                                      </div>
                                      <div className="serviceNameContainer">
                                        <Tooltip title={name}>
                                          <div>{truncateString(name, 8)}</div>
                                        </Tooltip>
                                        <Tooltip title="Change Color">
                                          <Box
                                            className={`Plannercolor`}
                                            onClick={() =>
                                              handleCrewMemberColor(
                                                crewMemberId,
                                                crewMemberColor
                                              )
                                            }
                                            sx={{
                                              backgroundColor: crewMemberColor,
                                              cursor: "pointer",
                                            }}
                                          />
                                        </Tooltip>
                                      </div>
                                    </div>
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {convertTime(totalAssignTimeOfWork)}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {convertTime(totalAssignTravelTime)}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {formatDistance(totalAssignTravelDistance)}
                                  </StyledTableCell>
                                </TableRow>
                              );
                            })}
                          <TableRow>
                            <StyledTableCell component="th" scope="row">
                              <Typography
                                fontWeight="500"
                                component="span"
                                variant="subtitle2"
                              >
                                Total
                              </Typography>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {convertTime(totalCrewWorkTime?.toFixed(2))}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {convertTime(totalCrewTravelTime?.toFixed(2))}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {formatDistance(totalCrewDistance)}
                            </StyledTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
                {/* <div className="plannerDetails">
                  <PlannerInputData />
                </div> */}
              </>
            )}
          </div>
        </div>
        <SelectPlanModal
          isOpen={isSelectPlanOpen}
          setIsOpen={setIsSelectPlanOpen}
        />
        <ColorPickerModal
          isOpen={isColorPickerOpen}
          setIsOpen={setIsColorPickerOpen}
          crewMemberColorData={crewMemberColorData}
        />
        </>
    );
  };
  
  export default PlanningDashboardPlannedData;
  