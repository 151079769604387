import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clickedPolygonParent: null,
};

const planningDashboardSlice = createSlice({
  name: "planningDashboard",
  initialState,
  reducers: {
    reset: () => initialState,
    setClickedPolygonParent: (state, action) => {
      return {
        ...state,
        clickedPolygonParent: action.payload,
      };
    },
  },
});

export const { reset, setClickedPolygonParent } = planningDashboardSlice.actions;

export default planningDashboardSlice.reducer;
