import React from "react";
import DashboardMapScreen from "./PlanningDashboardMapScreen";

const PlanningDashboard = () => {

  return (
    <>
      <DashboardMapScreen />
    </>
  );
};

export default PlanningDashboard;
